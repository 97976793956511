import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Box, boxesIntersect, useSelectionContainer } from '@air/react-drag-to-select';
import { ApolloError, NetworkStatus, Reference, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  closestCenter,
  CollisionDetection,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  getFirstCollision,
  KeyboardSensor,
  PointerSensor,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Alert, App, Badge, Empty, Flex, Spin } from 'antd';
import { createStyles } from 'antd-style';
import { useParams } from 'react-router-dom';
import { ReplaceWithReference } from 'types/ModelConnection';
import { PhotoCustomOrder } from 'types/PhotoCustomOrder';

import InfiniteScroll from 'react-infinite-scroll-component';

import { camelCase, uniq } from 'lodash';

import Container from 'Components/Atoms/Container';
import Text from 'Components/Atoms/Text';

import FilePicker from 'Components/Molecules/FilePicker';
import GalleryFolders from 'Components/Molecules/GalleryFolders';
import ListToolbar, { OrderOption as SortOption, PhotoFilter } from 'Components/Molecules/ListToolbar';
import PhotoElement from 'Components/Molecules/Photo';
import SelectionToolbar from 'Components/Molecules/SelectionToolbar';
import SortablePhoto from 'Components/Molecules/SortablePhoto';

import { AddPhotosToOrderPayload } from 'Forms/AddPhotosToOrder';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { usePhotoDrawer } from 'Hooks/PhotoDrawerProvider';
import { useUpload } from 'Hooks/Upload';
import { usePhotosCount } from 'Hooks/usePhotosCount';
import { useWindowSize } from 'Hooks/useWindowSize';

import getErrorCode from 'Helpers/GetErrorCode';
import { updateFolderPhotosCount } from 'Helpers/updateFolderPhotoCount';

import { getKeyIdentifier } from 'Operations/Cache/Policies/Gallery';

import {
  GalleryAdmin,
  GalleryPhotosOrder,
  GetPhotoQuery,
  PhotoAdmin,
  PhotoCharacteristic,
  PhotosSortField,
  PhotosSortOrder,
} from 'Operations/__generated__/graphql';

import { GET_FOLDERS } from 'Operations/Queries/Folder/GetFolders';
import { GET_GALLERY } from 'Operations/Queries/Gallery/GetGallery';
import { GET_GALLERY_PHOTOS } from 'Operations/Queries/Gallery/GetGalleryPhotos';
import { GET_PHOTO } from 'Operations/Queries/Photo/GetPhoto';

import { UPDATE_GALLERY } from 'Operations/Mutations/Gallery/UpdateGallery';
import { ADD_PHOTOS_TO_ORDER } from 'Operations/Mutations/Photo/AddPhotosToOrder';
import { DELETE_PHOTOS } from 'Operations/Mutations/Photo/DeletePhotos';
import { MOVE_PHOTO_TO_FOLDER } from 'Operations/Mutations/Photo/MovePhotoToFolder';
import { UPDATE_PHOTOS_ORDER } from 'Operations/Mutations/Photo/UpdatePhotosOrder';

const useStyles = createStyles(({ css, token }) => ({
  galleryPhotoContent: css`
    width: 100%;
    padding: 0 ${token.size}px ${token.size}px ${token.size}px;
    margin-bottom: ${token.size}px;
  `,
  filePickerContainer: css`
    height: 144px;
    margin: 12px;
    align-items: center;
  `,
  spinContainer: css`
    width: 100%;
    height: 300px;
  `,
  container: css`
    height: 100%;
  `,
  photoContainer: css`
    width: 100%;
    padding: 0 0 ${token.size}px ${token.size}px;
  `,
  empty: css`
    margin: ${token.sizeXL}px;
  `,
  dragContainer: css`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    overflow: hidden;
  `,
}));

const sortOptions: SortOption[] = [
  { fieldName: PhotosSortField.NAME, name: 'app.gallery.photos.sort.name', order: PhotosSortOrder.ASC },
  { fieldName: PhotosSortField.NAME, name: 'app.gallery.photos.sort.name', order: PhotosSortOrder.DESC },
  { fieldName: PhotosSortField.CUSTOM, name: 'app.gallery.photos.sort.custom' },
  { fieldName: PhotosSortField.RANDOM, name: 'app.gallery.photos.sort.random' },
];

interface GalleryPhotosTabProps {
  photosOrder: GalleryPhotosOrder;
  photosCustomOrder: PhotoCustomOrder;
}
interface PhotoBox extends Box {
  id: number;
}

const computePerPage = (windowWidth?: number) => {
  if (!windowWidth) {
    return 50;
  }
  let divisor = 700;
  if (windowWidth > 3000) {
    divisor = 400;
  } else if (windowWidth > 2000) {
    divisor = 500;
  }
  return Math.floor(windowWidth / divisor) * 25;
};

const GalleryPhotosTab = ({ photosOrder, photosCustomOrder }: GalleryPhotosTabProps) => {
  const { id } = useParams<{ id: string }>();
  const { message } = App.useApp();
  const galleryId = id ? parseInt(id, 10) : undefined;
  const lastSelectedItemId = useRef<number | null>(null);
  const selectableItems = useRef<PhotoBox[]>([]);
  const elementsContainerRef = useRef<HTMLDivElement | null>(null);
  const { t } = useContext(LocalizationContext);
  const { openDrawer } = usePhotoDrawer();
  const { width: windowWidth } = useWindowSize();
  const { openModal, closeModal } = useModals();
  const { styles } = useStyles();

  const { startUpload, isUploadRunning, galleryId: galleryIdUpload } = useUpload();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [selectedPhotos, setSelectedPhotos] = useState<number[]>([]);
  const [photosSortOrder, setPhotosSortOrder] = useState<string[]>([]);
  const [activePhotosIds, setActivePhotosIds] = useState<number[]>([]);
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [perPage, setPerPage] = useState<number>(computePerPage(window.innerWidth));

  const [listParams, setListParams] = useState<{
    filter: string | null;
    folderId: number | null;
    characteristics: PhotoCharacteristic[];
  }>({
    filter: null,
    folderId: -1,
    characteristics: [PhotoCharacteristic.AVAILABLE],
  });

  useEffect(() => {
    setPhotosSortOrder((photosCustomOrder[listParams.folderId || 'default'] || []).map(id => id.toString()));
  }, [photosCustomOrder, listParams.folderId]);

  useEffect(() => {
    setPerPage(computePerPage(windowWidth));
  }, [windowWidth]);

  const {
    data: getPhotosResult,
    loading: isPhotosLoading,
    fetchMore,
    networkStatus: getPhotosNetworkStatus,
    refetch: refetchPhotos,
  } = useQuery(GET_GALLERY_PHOTOS, {
    skip: listParams.folderId === -1 || !galleryId,
    variables: {
      galleryId: galleryId as number,
      where: {
        ...listParams,
        perPage,
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  });

  const { updatePhotosCount: updateGalleryFolderPhotoCount } = usePhotosCount({
    skip: listParams.folderId === -1,
    galleryId,
    characteristics: [PhotoCharacteristic.AVAILABLE],
    folderId: listParams.folderId,
    isRoot: false,
    queryName: 'photos',
  });

  const { updatePhotosCount: updateGalleryPhotoCount } = usePhotosCount({
    galleryId,
    characteristics: listParams.characteristics,
  });

  const { count: rootPhotoCount, updatePhotosCount: updateRootPhotoCount } = usePhotosCount({
    galleryId,
    folderId: null,
    characteristics: listParams.characteristics,
  });

  const gallery = getPhotosResult?.getGallery?.__typename === 'GalleryAdmin' ? getPhotosResult?.getGallery : undefined;

  const isAllPhotosFilter = !listParams.characteristics.includes(PhotoCharacteristic.ORDERED);

  const { photos, hasNextPage, page } = useMemo(() => {
    if (!gallery?.photos?.edges) return { photos: [], hasNextPage: true, page: 1 };

    let photosFiltered = gallery.photos.edges.filter(
      (p): p is PhotoAdmin => p.__typename === 'PhotoAdmin' && photosSortOrder.includes(p.id.toString()),
    );

    // Order photos by custom order
    photosFiltered = photosFiltered.slice().sort((a, b) => {
      const aIndex = photosSortOrder.indexOf(a.id.toString());
      const bIndex = photosSortOrder.indexOf(b.id.toString());
      return aIndex - bIndex;
    });

    if (listParams.filter) {
      const filterParam = listParams.filter;
      photosFiltered = photosFiltered.filter(x => x.name.indexOf(filterParam) >= 0);
    }

    const loadedCount = gallery.photos.edges.length;

    return {
      photos: photosFiltered,
      hasNextPage: gallery.photos._count > loadedCount,
      // Check if we have more photo loaded than the current page allows
      page: Math.floor(loadedCount / perPage),
    };
  }, [gallery?.photos?.edges, gallery?.photos?._count, photosSortOrder, listParams, perPage]);

  const onSelectionChange = useCallback((box: Box) => {
    const indexesToSelect: number[] = [];
    const scrollAwareBox = {
      ...box,
      top: box.top + window.scrollY,
    };
    selectableItems.current.forEach(item => {
      if (boxesIntersect(scrollAwareBox, item)) {
        indexesToSelect.push(item.id);
      }
    });
    if (indexesToSelect.length > 0) {
      setSelectedPhotos(indexesToSelect);
    }
  }, []);

  const { DragSelection } = useSelectionContainer({
    onSelectionChange,
    eventsElement: document.getElementById('photos-container'),
    shouldStartSelecting(target) {
      if (target instanceof HTMLElement) {
        let el = target;
        while (el.parentElement && !el.dataset.disableselect) {
          el = el.parentElement;
        }
        return el.dataset.disableselect !== 'true';
      }

      return true;
    },
  });

  const handleGetPhotoCompleted = useCallback((res: GetPhotoQuery | undefined) => {
    if (res?.getPhoto.__typename === 'PhotoAdmin') {
      openDrawer({
        photo: res.getPhoto,
        isLoading: false,
      });
    } else {
      message.error(t('app.gallery.photos.notFound'));
    }
  }, []);

  const [getPhoto] = useLazyQuery(GET_PHOTO, {
    fetchPolicy: 'network-only',
    onCompleted: handleGetPhotoCompleted,
  });

  const { data: folderData, loading: isFetchFoldersLoading } = useQuery(GET_FOLDERS, {
    skip: !galleryId,
    variables: {
      galleryId: galleryId as number,
      photoWhere: {
        characteristics: listParams.characteristics,
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const [deletePhotos, { loading: isDeletePhotoInProgress }] = useMutation(DELETE_PHOTOS);

  const [updateGallery] = useMutation(UPDATE_GALLERY, {
    refetchQueries: [
      {
        query: GET_GALLERY,
        variables: { where: { id: galleryId } },
      },
    ],
    onCompleted: () => {
      if (galleryId) {
        refetchPhotos({
          galleryId,
          where: { ...listParams, page: 1, perPage },
        });
      }
    },
  });

  const [updatePhotosOrder] = useMutation(UPDATE_PHOTOS_ORDER);
  const [movePhotoToFolder] = useMutation(MOVE_PHOTO_TO_FOLDER, {
    // We have to manually update the photos fields in the cache as they are custom fields
    update(cache, { data }) {
      if (data?.movePhotoToFolder) {
        const galleryRef = cache.identify({ __typename: 'GalleryAdmin', id: galleryId });
        const fromFolderId = listParams.folderId;
        const destinationFolderId = data.movePhotoToFolder[0]?.folder?.id || null;
        const photoMovedCount = data.movePhotoToFolder.length || 0;
        const photoMovedIds = data.movePhotoToFolder.map(({ id }) => id);

        const fromKeyIdentifier = getKeyIdentifier({
          characteristics: listParams.characteristics,
          folderId: listParams.folderId,
          queryName: 'photos',
        }) as 'photos';

        const toKeyIdentifier = getKeyIdentifier({
          characteristics: listParams.characteristics,
          folderId: destinationFolderId,
          queryName: 'photos',
        }) as 'photos';

        cache.modify<ReplaceWithReference<GalleryAdmin, 'photos'>>({
          id: galleryRef,
          fields: {
            // Remove the photo ids from the current folder in photosCustomOrder
            photosCustomOrder(existing) {
              const order = { ...(existing as PhotoCustomOrder) };
              const key = fromFolderId || 'default';

              return {
                ...order,
                [key]: order[key].filter((photoId: number) => !photoMovedIds.includes(photoId)),
              };
            },
            // Remove the photos from the current folder
            [fromKeyIdentifier]: (existing, { readField }) => {
              if (!('__ref' in existing) && existing?.edges?.length > 0) {
                const edges = existing.edges.filter(photoRef => {
                  const photoId = readField<number>('id', { ...photoRef });

                  return data.movePhotoToFolder.every(photo => photo.id !== photoId);
                });

                return {
                  ...existing,
                  _count: existing._count - photoMovedCount,
                  edges,
                };
              }

              return existing;
            },
          },
        });
        cache.modify<ReplaceWithReference<GalleryAdmin, 'photos'>>({
          id: galleryRef,
          fields: {
            // Add the photos to the new folder
            [toKeyIdentifier]: (existing, { toReference, readField }) => {
              if ('__ref' in existing) {
                return existing;
              }
              const newRefs = data.movePhotoToFolder
                .map(photo =>
                  toReference({
                    __typename: 'PhotoAdmin',
                    id: photo.id,
                  }),
                )
                .filter((ref): ref is Reference => !!ref && !!readField('name', ref));

              const edges = (existing?.edges || []).slice().concat(newRefs);

              return {
                ...existing,
                _count: existing._count + photoMovedCount,
                edges,
              };
            },
          },
        });

        // Increment the photo count of the folder destination
        if (destinationFolderId) {
          updateFolderPhotosCount({ folderId: destinationFolderId, count: photoMovedCount, cache });
        } else {
          updateRootPhotoCount(photoMovedCount);
        }

        // Decrement the photo count of the old folder
        if (listParams.folderId) {
          updateFolderPhotosCount({ folderId: listParams.folderId, count: -photoMovedCount, cache });
        } else {
          updateRootPhotoCount(-photoMovedCount);
        }

        cache.gc();
      }
    },
    onCompleted(mutationResult) {
      setSelectedPhotos([]);

      if (!mutationResult.movePhotoToFolder?.length) {
        message.error(t('app.message.gallery.photos.move.nothing_moved'));
      } else {
        message.success(
          t('app.message.gallery.photos.move.success', { count: mutationResult.movePhotoToFolder?.length }),
        );
      }
    },
  });

  const [addPhotosToOrder] = useMutation(ADD_PHOTOS_TO_ORDER, {
    update(cache, { data }) {
      if (data?.addPhotosToOrder.available && data?.addPhotosToOrder.available._count > 0) {
        const photoIdsToUpdate = data?.addPhotosToOrder.available.edges.map(({ id }) => id);

        for (const photoId of photoIdsToUpdate) {
          cache.modify({
            id: cache.identify({ __typename: 'PhotoAdmin', id: photoId }),
            fields: {
              characteristics(existing = []) {
                const newCharacteristics = uniq([...existing, PhotoCharacteristic.ORDERED]);
                return newCharacteristics;
              },
              isOrdered() {
                return true;
              },
            },
          });
        }

        cache.gc();
      }
    },
    onCompleted(mutationResult) {
      setSelectedPhotos([]);

      if (mutationResult.addPhotosToOrder.available?._count > 0) {
        message.success(
          t('app.message.gallery.photos.addToOrder.success', {
            count: mutationResult.addPhotosToOrder.available._count,
          }),
        );
      }
    },
  });

  const isFirstLoading = useMemo(() => isPhotosLoading && !photos?.length, [isPhotosLoading, photos?.length]);
  const isRefetchingPhotos = useMemo(() => {
    return (
      isPhotosLoading &&
      (getPhotosNetworkStatus === NetworkStatus.refetch || getPhotosNetworkStatus === NetworkStatus.setVariables)
    );
  }, [isPhotosLoading, getPhotosNetworkStatus]);

  const shouldDisplayFilepicker = isAllPhotosFilter && !isRefetchingPhotos && !isFirstLoading;

  useEffect(() => {
    if (elementsContainerRef.current) {
      selectableItems.current = [];
      Array.from(elementsContainerRef.current.children).forEach(item => {
        if (item.classList.contains('ImageCard')) {
          const id = parseInt(item.getAttribute('data-id') || '0', 10);

          const { left, top, width, height } = item.getBoundingClientRect();
          selectableItems.current.push({
            left,
            top,
            width,
            height,
            id,
          });
        }
      });
    }
  }, [photos, listParams]);

  useEffect(() => {
    // Listen for the keydown event shift key to be pressed
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setIsShiftPressed(true);
      }
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setIsShiftPressed(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', () => handleKeyDown);
      document.removeEventListener('keyup', () => handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (listParams.folderId === -1 && folderData?.getFolders) {
      if (folderData?.getFolders.length > 0) {
        const folderId = folderData?.getFolders[0].id;
        setListParams(params => ({ ...params, folderId }));
        if (photosCustomOrder[folderId] && photosCustomOrder[folderId].length > 0) {
          setPhotosSortOrder(photosCustomOrder[folderId].map(id => id.toString()));
        }
      } else {
        setListParams(params => ({ ...params, folderId: null }));
      }
    }
  }, [isFirstLoading, listParams.folderId, folderData, photosCustomOrder, setListParams, setPhotosSortOrder]);

  const handleOnClickImage = useCallback(
    async ({ photoId }: { photoId: number }) => {
      if (photoId && galleryId) {
        openDrawer({ isLoading: true });
        getPhoto({
          variables: {
            where: { galleryId, id: photoId },
          },
        });
      }
    },
    [galleryId, openDrawer],
  );

  const onFilesPicked = useCallback(
    (files: File[]) => {
      if (files && files.length > 0 && galleryId) {
        startUpload({
          files,
          targetGalleryId: galleryId,
          targetFolderId: listParams.folderId,
        });
      }
    },
    [galleryId, listParams, startUpload],
  );

  const onPhotoClicked = useCallback(
    ({ photoId }: { photoId: number }) => {
      const isSelected = selectedPhotos.includes(photoId);

      if (isShiftPressed && lastSelectedItemId.current) {
        const currentIdx = photos.findIndex(x => x.id === photoId);
        const lastSelectedx = photos.findIndex(x => x.id === lastSelectedItemId.current);

        return setSelectedPhotos(
          photos.slice(Math.min(currentIdx, lastSelectedx), Math.max(currentIdx, lastSelectedx) + 1).map(x => x.id),
        );
      }

      if (!isSelected) {
        lastSelectedItemId.current = photoId;
        return setSelectedPhotos(oldSelected => [...oldSelected, photoId]);
      } else {
        return setSelectedPhotos(oldSelected => oldSelected.filter(x => x !== photoId));
      }
    },
    [selectedPhotos, setSelectedPhotos, isShiftPressed, photos],
  );

  const onTextSearch = useCallback(
    (text: string | null) => {
      setListParams(params => ({ ...params, filter: text }));
    },
    [setListParams],
  );

  const onDeleteSelection = useCallback(async () => {
    const photosToDeleteCount = selectedPhotos.length;
    try {
      await deletePhotos({
        variables: {
          where: {
            ids: selectedPhotos,
          },
        },
        update(cache, mutationResult) {
          const deletedPhotos = mutationResult.data?.deletePhotos?.ids;
          if (deletedPhotos) {
            for (const deletedPhoto of deletedPhotos) {
              updateGalleryPhotoCount(-1);
              updateGalleryFolderPhotoCount(-1);
              if (listParams.folderId) {
                updateFolderPhotosCount({
                  folderId: listParams.folderId,
                  count: -1,
                  cache,
                });
              } else {
                updateRootPhotoCount(-1);
              }
              cache.evict({ id: `PhotoAdmin:${deletedPhoto}` });
            }
            cache.gc();
          }
          setSelectedPhotos([]);

          if (!deletedPhotos?.length) {
            message.error(t('app.message.gallery.photos.delete.nothing_deleted'));
          } else if (deletedPhotos.length !== photosToDeleteCount) {
            message.error(t('app.message.gallery.photos.delete.partial_deletion'));
          } else {
            message.success(t('app.message.gallery.photos.delete.success', { count: deletedPhotos?.length }));
          }
        },
      });
    } catch (error) {
      const errorCode = getErrorCode(error as ApolloError);
      switch (errorCode) {
        case 'ORDERED_PHOTO':
          message.error(t(`app.message.error.${camelCase(errorCode)}`, { count: photosToDeleteCount }));
          break;
        default:
          message.error(t('app.message.error.somethingWentWrong'));
          break;
      }
    }
  }, [
    selectedPhotos,
    deletePhotos,
    listParams,
    updateGalleryFolderPhotoCount,
    updateFolderPhotosCount,
    updateRootPhotoCount,
  ]);

  const onCancelSelection = () => {
    setSelectedPhotos([]);
  };

  const onMoveSelection = (folderId: number | null) => {
    if (folderId === listParams.folderId) {
      return;
    }
    try {
      movePhotoToFolder({
        variables: {
          where: {
            ids: selectedPhotos,
          },
          data: {
            folderId,
          },
        },
      });
    } catch (error) {
      message.error(t('app.message.error.somethingWentWrong'));
    }
  };

  const onLightroomSelection = useCallback(() => {
    const photosName = selectedPhotos
      .map(photoId => {
        const photo = photos.find(x => x.id === photoId);

        // Remove extension
        return photo?.name.replace(/\.[^/.]+$/, '');
      })
      .filter(name => !!name);

    navigator.clipboard.writeText(photosName.join(', '));

    message.success(t('app.message.gallery.photos.clipboard.success', { count: selectedPhotos.length }));
  }, [selectedPhotos, photos, t]);

  const onSortChanged = useCallback(
    (selectedOption: SortOption) => {
      if (!galleryId) {
        return;
      }
      let photosOrder = GalleryPhotosOrder.CUSTOM;

      if (selectedOption.fieldName === 'random') {
        photosOrder = GalleryPhotosOrder.RANDOM;
      } else if (selectedOption.fieldName === 'name') {
        photosOrder =
          selectedOption.order === 'asc' ? GalleryPhotosOrder.FILENAME_ASC : GalleryPhotosOrder.FILENAME_DESC;
      }

      updateGallery({
        variables: {
          where: { id: galleryId },
          data: {
            photosOrder,
          },
        },
      });
    },
    [galleryId, updateGallery, listParams, perPage],
  );

  const onFilterChanged = useCallback(
    (selectedOption: PhotoFilter) => {
      setListParams(params => ({
        ...params,
        characteristics: uniq([selectedOption, PhotoCharacteristic.AVAILABLE]),
      }));
      setSelectedPhotos([]);

      refetchPhotos();
    },
    [refetchPhotos],
  );

  const handleDragCancel = useCallback(() => {
    setActivePhotosIds([]);
    setPhotosSortOrder((photosCustomOrder?.[listParams.folderId || 'default'] || []).map(id => id.toString()));
  }, [listParams.folderId, photosCustomOrder]);

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const { active } = event;
      if (!active.id) {
        return;
      }

      const activeId = parseInt(active.id.toString(), 10);

      const activeIds = selectedPhotos.includes(activeId) ? selectedPhotos : [activeId];

      setActivePhotosIds(activeIds);

      setPhotosSortOrder(orderIds => orderIds.filter(id => !activeIds.includes(parseInt(id, 10)) || id === active.id));
    },
    [selectedPhotos],
  );

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      if (!galleryId) {
        return;
      }

      const { active, over } = event;

      if (!over?.id) {
        handleDragCancel();
        return;
      }

      // If the drag ended on a folder, move the photo to that folder.
      if (over.id.toString().includes('folder')) {
        const folderId = over?.data?.current?.id ? parseInt(over.data.current.id) : null;
        if (listParams.folderId !== folderId) {
          // Remove photo from current folder
          setPhotosSortOrder(photosSortOrder => photosSortOrder.filter(x => x !== active.id));

          // Move photo to new folder
          await movePhotoToFolder({
            variables: {
              where: {
                ids: activePhotosIds,
              },
              data: {
                folderId,
              },
            },
          });
          message.success(t('app.message.gallery.photos.move.success', { count: 1 }));

          setActivePhotosIds([]);
          return;
        }
      } else {
        if (active.id !== over.id) {
          const newIndex = photosSortOrder.indexOf(over.id.toString());

          if (newIndex !== undefined) {
            // Move ids from activePhotosIds to the new index in photosSortOrder
            const newPhotosSortOrder = photosSortOrder.filter(x => !activePhotosIds.includes(parseInt(x, 10)));
            const newActivePhotosIds = activePhotosIds.map(id => id.toString());
            newPhotosSortOrder.splice(newIndex, 0, ...newActivePhotosIds);

            setPhotosSortOrder(newPhotosSortOrder);

            await updatePhotosOrder({
              variables: {
                where: {
                  galleryId,
                  folderId: listParams.folderId,
                },
                data: {
                  ids: newPhotosSortOrder.map(id => parseInt(id, 10)),
                },
              },
            });
          }
          setActivePhotosIds([]);
          return;
        }
      }

      handleDragCancel();
    },
    [photosSortOrder, updatePhotosOrder],
  );

  const onFolderChange = useCallback(
    (folderId: number | null) => {
      setListParams(params => ({ ...params, folderId }));

      handleDragCancel();
      onCancelSelection();
    },
    [photosCustomOrder],
  );

  const getMorePhotos = useCallback(async () => {
    if (hasNextPage && !isPhotosLoading && galleryId) {
      await fetchMore({
        variables: {
          galleryId,
          where: { page: page + 1, perPage, ...listParams },
        },
      });
    }
  }, [isPhotosLoading, fetchMore, hasNextPage, listParams, page, perPage]);

  const selectAllPhotos = useCallback(() => {
    let folderCount = 0;
    // Only take into account folders if we are not filtering by all photos
    if (listParams.folderId) {
      folderCount = folderData?.getFolders.find(folder => folder.id === listParams.folderId)?.photos._count || 0;
    } else {
      folderCount = rootPhotoCount || 0;
    }

    if (photos.length === folderCount || !isAllPhotosFilter) {
      setSelectedPhotos(photos.map(photo => photo.id));
    } else {
      setSelectedPhotos(photosSortOrder.map(photoId => parseInt(photoId)));
    }
  }, [folderData?.getFolders, photos, photosSortOrder, rootPhotoCount, listParams.folderId]);

  const activePhotos = useMemo(
    () => photos.filter(photo => activePhotosIds.includes(photo.id) && !!photo.asset),
    [activePhotosIds, photos],
  );

  const renderDragOverlay = useCallback(() => {
    if (!activePhotos || activePhotos.length === 0) {
      return null;
    }

    const photo = activePhotos[0];

    return photo.asset ? (
      <Badge count={activePhotosIds.length > 1 ? activePhotosIds.length : 0}>
        <Flex className={styles.dragContainer}>
          <PhotoElement key={photo.id} id={photo.id} asset={photo.asset} />
        </Flex>
      </Badge>
    ) : null;
  }, [activePhotos, activePhotosIds]);

  const handleAddPhotosToOrder = useCallback(async ({ values }: AddPhotosToOrderPayload) => {
    try {
      if (!values.orderId) {
        throw new Error('No order id provided');
      }
      await addPhotosToOrder({
        variables: {
          where: {
            galleryId: values.galleryId,
            orderId: values.orderId,
            ids: values.photoIds,
          },
        },
      });

      closeModal('ADD_PHOTOS_TO_ORDER_MODAL');
    } catch (error) {
      message.error(t('app.message.error.somethingWentWrong'));
    }
  }, []);

  const onAddToOrderClicked = useCallback(() => {
    if (!galleryId || !gallery) {
      return;
    }

    openModal('ADD_PHOTOS_TO_ORDER_MODAL', {
      defaultValues: {
        galleryId,
        photoIds: selectedPhotos,
      },
      onSubmit: handleAddPhotosToOrder,
      workmode: gallery.workmode,
    });
  }, [selectedPhotos]);

  /**
   * Custom collision detection strategy optimized for multiple containers
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(args => {
    const pointerIntersections = pointerWithin(args);
    const intersections =
      pointerIntersections.length > 0
        ? // If there are droppables intersecting with the pointer, return those
          pointerIntersections
        : rectIntersection(args);
    const overId = getFirstCollision(intersections, 'id');

    if (overId?.toString().includes('folder')) {
      return intersections;
    }

    return closestCenter({
      ...args,
      droppableContainers: args.droppableContainers.filter(container => !container.id.toString().includes('folder')),
    });
  }, []);

  return (
    <Flex gap="middle" vertical>
      {isUploadRunning && galleryIdUpload === galleryId && (
        <Alert message={t('app.gallery.photos.upload.alert.inProgress')} type="warning" />
      )}
      {isUploadRunning && galleryIdUpload !== galleryId && (
        <Alert message={t('app.gallery.photos.upload.alert.inProgressAnotherOne')} type="warning" />
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={collisionDetectionStrategy}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext items={photosSortOrder} strategy={rectSortingStrategy}>
          <Container className={styles.container} block>
            {!isFirstLoading && galleryId && (
              <GalleryFolders
                galleryId={galleryId}
                defaultFolder={listParams.folderId}
                characteristics={listParams.characteristics}
                onChange={onFolderChange}
                folders={folderData?.getFolders || []}
                isLoading={isFetchFoldersLoading}
                rootCount={rootPhotoCount || 0}
              />
            )}
            <Container direction="column" flex={1} block position="relative" id="photos-container">
              <DragSelection />
              {(!isUploadRunning || galleryIdUpload !== galleryId) && (
                <Flex className={styles.photoContainer} flex={1} justify="space-between">
                  <SelectionToolbar
                    selectionCount={selectedPhotos.length}
                    onAddToOrderClicked={onAddToOrderClicked}
                    onDelete={onDeleteSelection}
                    onCancel={onCancelSelection}
                    onMove={onMoveSelection}
                    onLightroom={onLightroomSelection}
                    folders={folderData?.getFolders || []}
                    selectedFolderId={listParams.folderId}
                    working={isDeletePhotoInProgress}
                    onSelectAll={!isFirstLoading && photos?.length ? selectAllPhotos : undefined}
                  />

                  <ListToolbar
                    sortOptions={sortOptions}
                    onSearch={onTextSearch}
                    onSortChanged={onSortChanged}
                    photosOrder={photosOrder}
                    onFilterChanged={onFilterChanged}
                  />
                </Flex>
              )}
              {(isRefetchingPhotos || isFirstLoading) && (
                <Flex className={styles.spinContainer} justify="center" align="center">
                  <Spin size="large" />
                </Flex>
              )}

              <Container direction="column" align="center" block>
                <InfiniteScroll
                  dataLength={photos?.length || 0}
                  next={getMorePhotos}
                  hasMore={hasNextPage}
                  loader={
                    !isFirstLoading && (
                      <Container justify="center">
                        <Spin />
                      </Container>
                    )
                  }
                >
                  {shouldDisplayFilepicker && photos.length === 0 && (
                    <Text>
                      {listParams.folderId === null
                        ? t('app.gallery.photos.emptyWithoutFolder')
                        : t('app.gallery.photos.emptyFolder')}
                    </Text>
                  )}
                  <Container
                    ref={elementsContainerRef}
                    className={styles.galleryPhotoContent}
                    direction="row"
                    block
                    justify="center"
                    align="center"
                  >
                    {shouldDisplayFilepicker && (
                      <Flex className={styles.filePickerContainer} justify="center" align="center">
                        <FilePicker
                          enabled={!isUploadRunning}
                          multiple={true}
                          onFilesPicked={onFilesPicked}
                          filetypes={['.jpg', '.jpeg']}
                        />
                      </Flex>
                    )}

                    {!isFirstLoading && photos.length === 0 && (
                      <Empty
                        className={styles.empty}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                          listParams.characteristics.includes(PhotoCharacteristic.ORDERED)
                            ? t('app.gallery.photos.noOrderedPhoto')
                            : t('app.gallery.photos.noPhoto')
                        }
                      />
                    )}

                    {!isRefetchingPhotos &&
                      !isFirstLoading &&
                      photos?.map(
                        ({ asset, id: photoId, name, isOrdered, unreadCommentsCount }) =>
                          asset?.noWmThumbSmall && (
                            <SortablePhoto
                              key={photoId}
                              id={photoId}
                              name={name}
                              asset={asset}
                              isPhotoSelected={selectedPhotos.includes(photoId)}
                              isOrdered={isOrdered}
                              unreadCommentsCount={unreadCommentsCount}
                              isSortable={!isUploadRunning || (isUploadRunning && galleryIdUpload !== galleryId)}
                              handleOnClickImage={handleOnClickImage}
                              handleOnSelectPhoto={onPhotoClicked}
                            />
                          ),
                      )}
                    <DragOverlay>{renderDragOverlay()}</DragOverlay>
                  </Container>
                </InfiniteScroll>
              </Container>
            </Container>
          </Container>
        </SortableContext>
      </DndContext>
    </Flex>
  );
};

export default GalleryPhotosTab;
