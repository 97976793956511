import { gql } from 'Operations/__generated__';

export const GET_ORDERS_FOR_LABO = gql(/* GraphQL */ `
  query GetOrdersForLabo($where: GetOrdersWhereInput!) {
    getOrdersForLabo(where: $where) {
      _count
      edges {
        id
        gallery {
          id
          userId
          name
          contact {
            ...ContactCoreFields
          }
        }
        createdAt
        inTransitAt
        inWaitingAt
        isPaid
        isValidated
        invoice {
          id
          url
          ttcPrice
          isReceipt
        }
        ... on OrderAdmin {
          status
          orderedByFotostudio
          photographerMustReceiveOrder
          handlers
          laboOrderProcessedAt
          seller {
            email
            companyName
            companyAddress
            street
            zipcode
            city
            country
          }
          contact {
            ...ContactCoreFields
          }
        }
      }
    }
  }
`);
