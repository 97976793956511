import { useCallback, useContext } from 'react';

import { useMutation } from '@apollo/client';
import { Alert, App } from 'antd';

import GridConfig from 'Components/Molecules/GridConfig';

import { Metrics } from 'Themes';

import { LocalizationContext } from 'i18n';

import { useGalleryCommon } from 'Hooks/useGalleryCommon';

import { GridSize, GridSpacing, WatermarkMode } from 'Operations/__generated__/graphql';

import { UPDATE_GALLERY_APPEARANCE } from 'Operations/Mutations/GalleryAppearance/UpdateGalleryAppearance';

const GallerySpacingTab = ({ galleryId, galleryPresetId }: { galleryId?: number; galleryPresetId?: number }) => {
  const { message } = App.useApp();
  const { t } = useContext(LocalizationContext);
  const { data, rawData } = useGalleryCommon({ galleryId, galleryPresetId });

  const [updateGalleryAppearance] = useMutation(UPDATE_GALLERY_APPEARANCE);

  const selectGridCallback = useCallback(
    ({ size, spacing }: { size: GridSize; spacing: GridSpacing }) => {
      try {
        if (data?.galleryAppearance?.id) {
          updateGalleryAppearance({
            variables: {
              where: {
                id: data.galleryAppearance.id,
              },
              data: {
                size,
                spacing,
              },
            },
          });

          message.success(t('app.message.gallery.appearance.update.success'));
        }
      } catch (error) {
        console.log(error);

        message.error(t('app.message.gallery.appearance.update.error'));
      }
    },
    [data?.galleryAppearance?.id, message, t, updateGalleryAppearance],
  );

  if (!data?.galleryAppearance) {
    return null;
  }

  return (
    <>
      {rawData?.__typename === 'GalleryAdmin' && rawData.watermarkMode === WatermarkMode.WEB && (
        <Alert
          type="info"
          style={{ whiteSpace: 'pre-wrap', marginBottom: Metrics.baseMargin }}
          message={t('app.gallery.appearance.watermarkThumb')}
        />
      )}
      <GridConfig
        handleSelectGrid={selectGridCallback}
        currentGridId={
          data.galleryAppearance.spacing && data.galleryAppearance.size
            ? `${data.galleryAppearance.spacing}-${data.galleryAppearance.size}`
            : undefined
        }
      />
    </>
  );
};

export default GallerySpacingTab;
