import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { App, Flex } from 'antd';
import { createStyles } from 'antd-style';
import { ColorFactory } from 'antd/es/color-picker/color';

import Title from 'Components/Atoms/Title';

import { EditGalleryCoverForm, EditGalleryCoverPayload } from 'Forms/GalleryCover';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { useGalleryCommon } from 'Hooks/useGalleryCommon';

import { getRGBString } from 'Helpers/TransformColor';

import {
  Align,
  CoverType,
  FontStyle,
  FontWeight,
  GalleryAppearanceUpdateInputType,
  GalleryAppearenceLogo,
  Position,
  ShootedByType,
} from 'Operations/__generated__/graphql';

import { UPDATE_GALLERY_APPEARANCE } from 'Operations/Mutations/GalleryAppearance/UpdateGalleryAppearance';

const useStyles = createStyles(({ css, token }) => ({
  separator: css`
    height: 1px;
    width: 100%;
    background-color: ${token.colorBorder};
  `,
  coverButton: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border: 4px solid rgba(255, 255, 255, 0);

    &.active,
    &:hover {
      border-color: ${token.colorPrimary};
    }
  `,
  coverImage: css`
    display: block;
    width: 100%;
    height: auto;
  `,
}));

const coverOrder: Record<string, number> = {
  [CoverType.ALPHA]: 1,
  [CoverType.DELTA]: 2,
  [CoverType.EPSILON]: 3,
  [CoverType.BETA]: 4,
  [CoverType.GAMMA]: 5,
  [CoverType.ZETA]: 6,
};

const coverImages: Record<string, any> = {
  [CoverType.ALPHA]: Images.coverAlpha,
  [CoverType.DELTA]: Images.coverDelta,
  [CoverType.EPSILON]: Images.coverEpsilon,
  [CoverType.BETA]: Images.coverBeta,
  [CoverType.GAMMA]: Images.coverGamma,
  [CoverType.ZETA]: Images.coverZeta,
};

const covers = Object.keys(CoverType).map(coverTypeValue => ({
  order: coverOrder[coverTypeValue],
  key: coverTypeValue,
  img: coverImages[coverTypeValue],
}));

const GalleryCoverTab = ({ galleryId, galleryPresetId }: { galleryId?: number; galleryPresetId?: number }) => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const { styles, cx } = useStyles();

  const { data, rawData, refetch } = useGalleryCommon({ galleryId, galleryPresetId });

  const [coverType, setCoverType] = useState<CoverType>(data?.galleryAppearance?.coverType || CoverType.ALPHA);

  const [updateGalleryAppearance] = useMutation(UPDATE_GALLERY_APPEARANCE, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleCoverClick = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { coverType }: { coverType: CoverType },
  ) => {
    e.preventDefault();

    setCoverType(coverType);
  };

  const handleCoverTypeChange = useCallback((coverType: CoverType) => {
    setCoverType(coverType);
  }, []);

  const updateGalleryCover = async ({ values: { id, ...data }, formikBag }: EditGalleryCoverPayload) => {
    try {
      const updateInput: GalleryAppearanceUpdateInputType = {
        backgroundColor: getRGBString(data.backgroundColor),
        coverInfoPosition: data.coverInfoPosition,
        coverPhotoId: data.coverPhotoId,
        coverType: data.coverType,
        font: data.font,
        overlayAlpha: data.overlayAlpha,
        shootedByAlign: data.shootedByAlign,
        shootedByType: data.shootedByType,
        textColor: getRGBString(data.textColor),
        titleFontStyle: data.isTitleFontItalic ? FontStyle.ITALIC : FontStyle.NORMAL,
        titleFontWeight: data.isTitleFontBold ? FontWeight.BOLD : FontWeight.NORMAL,
        titleFontSize: data.titleFontSize,
        withOverlay: data.withOverlay,
        withShootedBy: data.withShootedBy,
        logoType: data.isLogoActivated ? GalleryAppearenceLogo.DEFAULT : GalleryAppearenceLogo.NONE,
        isCoverWatermarked: data.isCoverWatermarked,
        isFullImage: !!data.isFullImage,
        focalPoint: data.focalPoint,
      };
      await updateGalleryAppearance({
        variables: {
          where: { id },
          data: updateInput,
        },
      });

      message.success(t('app.message.gallery.cover.update.success'));
      formikBag.setSubmitting(false);
    } catch (error) {
      console.log(error);

      message.error('Cover update error.');
      formikBag.setSubmitting(false);
    }
  };

  return (
    data?.id &&
    data.galleryAppearance &&
    data.theme && (
      <div style={{ width: '100%' }}>
        <Flex vertical gap="middle">
          <Title level="h5">{t('app.gallery.cover.title')}</Title>

          <Flex>
            {covers.map(cover => (
              <a
                key={cover.key}
                className={cx(styles.coverButton, coverType === cover.key && 'active')}
                href="#"
                onClick={e => handleCoverClick(e, { coverType: cover.key as CoverType })}
              >
                <img className={styles.coverImage} src={cover.img} />
              </a>
            ))}
          </Flex>

          <div className={styles.separator} />
          <Flex flex={1} vertical justify="center" align="center">
            <EditGalleryCoverForm
              defaultValues={{
                backgroundColor: new ColorFactory(`rgb(${data.galleryAppearance.backgroundColor || '255,255,255'})`),
                coverInfoPosition: data.galleryAppearance.coverInfoPosition || Position.CENTER,
                coverPhotoId: data.galleryAppearance.coverPhotoId,
                coverType: data.galleryAppearance.coverType || CoverType.ALPHA,
                font: data.galleryAppearance.font || 'Domine',
                isCoverWatermarked: !!data.galleryAppearance.isCoverWatermarked,
                id: data.galleryAppearance.id,
                isLogoActivated: data.galleryAppearance.logoType !== GalleryAppearenceLogo.NONE,
                isPreset: data.__typename === 'GalleryPreset',
                isTitleFontBold: data.galleryAppearance.titleFontWeight === FontWeight.BOLD,
                isTitleFontItalic: data.galleryAppearance.titleFontStyle === FontStyle.ITALIC,
                overlayAlpha: data.galleryAppearance.overlayAlpha || 0.5,
                shootedByAlign: data.galleryAppearance.shootedByAlign || Align.LEFT,
                shootedByType: data.galleryAppearance.shootedByType || ShootedByType.HORIZONTAL_BOTTOM,
                textColor: new ColorFactory(`rgb(${data.galleryAppearance.textColor || '0,0,0'})`),
                titleFontSize: data.galleryAppearance.titleFontSize || 24,
                withOverlay: !!data.galleryAppearance.withOverlay,
                withShootedBy: !!data.galleryAppearance.withShootedBy,
                isFullImage: !!data.galleryAppearance.isFullImage,
                focalPoint: {
                  x: data.galleryAppearance.focalPoint?.x || 50,
                  y: data.galleryAppearance.focalPoint?.y || 50,
                },
              }}
              galleryId={data.id}
              coverType={coverType}
              name={data.name || '-'}
              availableAt={rawData?.__typename === 'GalleryAdmin' ? rawData.availableAt : undefined}
              logoUrl={data.galleryAppearance.company?.logoUrl}
              onChangeCoverType={handleCoverTypeChange}
              onSubmit={updateGalleryCover}
              shootedAt={rawData?.__typename === 'GalleryAdmin' ? rawData.shootedAt : undefined}
              shootedByName={data.galleryAppearance.company?.name}
              theme={data.theme}
            />
          </Flex>
        </Flex>
      </div>
    )
  );
};

export default GalleryCoverTab;
