import React, { useContext, useMemo } from 'react';

import { createStyles } from 'antd-style';

import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CodeToggle,
  CreateLink,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  MDXEditorProps,
  toolbarPlugin,
  UndoRedo,
} from '@mdxeditor/editor';
import { useField } from 'formik';

import { LocalizationContext } from 'i18n';

export enum EditorButton {
  BOLD_ITALIC_UNDERLINE,
  CODE,
  UNORDERED_LIST,
  ORDERED_LIST,
  LINK,
  UNDO_REDO,
  BLOCK_TYPE,
}

interface MarkdownEditorProps extends Omit<MDXEditorProps, 'markdown'> {
  name: string;
  buttons?: EditorButton[];
}

const useStyles = createStyles(({ css, token }) => ({
  editor: css`
    .MarkdownEditor__contentEditable {
      h1 {
        font-size: 1.6em;
        margin-block-end: 0.83em;
        border-bottom: 1px solid ${token.colorBorder};
        font-weight: 600;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      strong {
        font-weight: inherit;
      }
    }
  `,
}));

const MarkdownEditor = ({ name, className, buttons = [], ...props }: MarkdownEditorProps) => {
  const ref = React.useRef(null);
  const { t } = useContext(LocalizationContext);
  const [field, meta, helpers] = useField(name);
  const { styles, cx } = useStyles();

  const handleChange = (markdown: string) => {
    helpers.setValue(markdown);
  };

  const renderToolbarContents = useMemo(
    () => () =>
      (
        <>
          {buttons.includes(EditorButton.BLOCK_TYPE) && <BlockTypeSelect />}
          {buttons.includes(EditorButton.UNDO_REDO) && <UndoRedo />}
          {buttons.includes(EditorButton.BOLD_ITALIC_UNDERLINE) && <BoldItalicUnderlineToggles />}
          {buttons.includes(EditorButton.CODE) && <CodeToggle />}
          {buttons.includes(EditorButton.UNORDERED_LIST) && <ListsToggle options={['bullet']} />}
          {buttons.includes(EditorButton.ORDERED_LIST) && <ListsToggle options={['number']} />}
          {buttons.includes(EditorButton.LINK) && <CreateLink />}
        </>
      ),
    [buttons],
  );

  const plugins = useMemo(() => {
    const pluginsList = [
      toolbarPlugin({
        toolbarContents: renderToolbarContents,
      }),
    ];

    if (buttons.includes(EditorButton.LINK)) {
      pluginsList.push(linkPlugin());
      pluginsList.push(linkDialogPlugin());
    }

    if (buttons.includes(EditorButton.UNORDERED_LIST) || buttons.includes(EditorButton.ORDERED_LIST)) {
      pluginsList.push(listsPlugin());
    }

    if (buttons.includes(EditorButton.BLOCK_TYPE)) {
      pluginsList.push(headingsPlugin());
    }
    return pluginsList;
  }, [renderToolbarContents, buttons]);

  return (
    <div className={className}>
      <MDXEditor
        ref={ref}
        {...props}
        markdown={field.value || ''}
        onChange={handleChange}
        plugins={plugins}
        contentEditableClassName="MarkdownEditor__contentEditable"
        className={cx(styles.editor)}
        translation={(key, defaultValue, interpolations) =>
          t(`markdownEditor.${key}`, { defaultValue, ...interpolations })
        }
      />
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </div>
  );
};

export default MarkdownEditor;
