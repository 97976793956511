import React, { ChangeEvent, forwardRef, useCallback, useContext, useRef, useState } from 'react';

import { App } from 'antd';
import { createStyles } from 'antd-style';

import { FieldHookConfig, useField } from 'formik';

import Icon from 'Components/Atoms/Icon';

import { LocalizationContext } from 'i18n';

const useStyles = createStyles(({ css, token }) => ({
  button: css`
    width: 100%;
    height: 100%;
    border: 2px dashed ${token.colorBorder};
    padding: ${token.size}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: none;
    cursor: pointer;
    transition: border-color 0.3s;
    background-color: ${token.colorBgLayout};
    &:hover {
      border-color: ${token.colorPrimary};
    }
  `,
  infoText: css`
    margin-top: 8px;
    font-size: 12px;
    color: ${token.colorTextSecondary};
    text-align: center;
  `,
  previewImage: css`
    position: relative;
    max-width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: ${token.colorBgLayout};
  `,
  image: css`
    width: 100%;
    height: 100%;
    object-fit: contain;
  `,
  overlay: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    opacity: 0;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
    }
  `,
  overlayButtons: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  `,
  overlayButton: css`
    margin: 0 5px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  `,
  overlayInfo: css`
    font-size: 12px;
    color: white;
    text-align: center;
    padding: 0 8px;
    margin-top: 8px;
  `,
}));

const ImageInput = forwardRef<
  HTMLInputElement,
  FieldHookConfig<File | FileList | null> & {
    accept: string;
    handleOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    preview?: string;
    maxFileSize?: number; // Maximum file size in bytes
    imageInfo?: string; // Information about recommended image aspect ratio or size
  }
>((props, ref) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const { styles } = useStyles();
  const { t } = useContext(LocalizationContext);
  const { modal } = App.useApp();

  const [field, meta, helpers] = useField(props);
  const { onChange, onBlur, value } = field;

  const [preview, setPreview] = useState<string | null>(props.preview || null);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      helpers.setError(undefined);

      if (!event.target.files) {
        helpers.setValue(null);
        return;
      }

      // Check file size
      if (props.maxFileSize && event.target.files.length === 1) {
        const file = event.target.files[0];
        if (file.size > props.maxFileSize) {
          const maxSizeMB = (props.maxFileSize / (1024 * 1024)).toFixed(2);
          helpers.setError(t('app.forms.errors.fileTooLarge', { maxSize: maxSizeMB }));
          modal.error({
            title: t('app.forms.errors.fileTooLarge.title', { maxSize: maxSizeMB }),
            content: t('app.forms.errors.fileTooLarge.description', { maxSize: maxSizeMB }),
            okText: t('app.common.ok'),
          });

          // Reset the file input value so that selecting the same file will trigger onChange again
          if (fileInput.current) {
            fileInput.current.value = '';
          }
          return;
        }
      }

      if (event.target.files.length > 1) {
        helpers.setValue(event.target.files);
      } else if (event.target.files.length === 1) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result as string);
        };
        reader.readAsDataURL(file);

        helpers.setValue(event.target.files[0]);
      }

      if (props.handleOnChange) {
        props.handleOnChange(event);
      }

      helpers.setTouched(true);
      onChange(event);
      onBlur(event);
    },
    [helpers, onChange, onBlur, props, t, modal],
  );

  const onButtonClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      if (fileInput && fileInput.current) {
        helpers.setValue(field.value || null);
        fileInput.current.click();
      }
    },
    [helpers, field],
  );

  const handleRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      helpers.setValue(null);
      setPreview(null);
    },
    [helpers],
  );

  return (
    <div ref={ref} className={props.className}>
      {preview ? (
        <div className={styles.previewImage} onClick={onButtonClick}>
          <img src={preview} alt="Preview" className={styles.image} />
          <div className={styles.overlay}>
            <div className={styles.overlayButtons}>
              <button className={styles.overlayButton} onClick={handleRemove}>
                <Icon size={24} name="delete" />
              </button>
              <button className={styles.overlayButton}>
                <Icon size={24} name="edit" />
              </button>
            </div>
            {props.imageInfo && <div className={styles.overlayInfo}>{props.imageInfo}</div>}
          </div>
        </div>
      ) : (
        <button onClick={onButtonClick} type="button" disabled={props.disabled} className={styles.button}>
          <Icon name="add-photo" size={32} />
          {props.imageInfo && <div className={styles.infoText}>{props.imageInfo}</div>}
        </button>
      )}
      <input
        type="file"
        ref={fileInput}
        multiple={props.multiple}
        style={{ display: 'none' }}
        onChange={handleChange}
        accept={props.accept}
      />
    </div>
  );
});

export default ImageInput;
