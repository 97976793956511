import React, { useContext } from 'react';

import { Button, Flex } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input, Switch } from 'formik-antd';

import { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

export interface RedsysPaymentFormValues {
  merchantCode?: string;
  terminal?: string;
  secretKey?: string;
}

const RedsysPayment = <T extends RedsysPaymentFormValues>({ initialValues, isSubmitting, values }: FormikProps<T>) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Form layout="vertical">
      <Form.Item
        label={t('app.redsys.merchantCode.label')}
        extra={t('app.redsys.merchantCode.description')}
        name="merchantCode"
        hasFeedback={false}
      >
        <Input name="merchantCode" placeholder={'123456789'} size="large" />
      </Form.Item>
      <Form.Item
        label={t('app.redsys.terminal.label')}
        extra={t('app.redsys.terminal.description')}
        name="terminal"
        hasFeedback={false}
      >
        <Input name="terminal" placeholder={'000'} size="large" />
      </Form.Item>
      <Form.Item
        label={t('app.redsys.secretKey.label')}
        extra={t('app.redsys.secretKey.description')}
        name="secretKey"
        hasFeedback={false}
      >
        <Input.Password name="secretKey" placeholder={'******'} size="large" defaultValue={initialValues.secretKey} />
      </Form.Item>
      <Flex justify="flex-end" align="center">
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
          {t('app.common.edit')}
        </Button>
      </Flex>
    </Form>
  );
};

const redsysPaymentSchema = yup.object({
  merchantCode: yup.string().trim(),
  terminal: yup.string().trim(),
  secretKey: yup.string().trim(),
});

const redsysPaymentFormSchema: yup.SchemaOf<RedsysPaymentFormValues> = redsysPaymentSchema.defined();

export interface OnSubmitPayload {
  values: RedsysPaymentFormValues;
  formikBag: FormikBag<RedsysPaymentFormProps, RedsysPaymentFormValues>;
}

export interface RedsysPaymentFormProps {
  onSubmit: (payload: OnSubmitPayload) => void;
  defaultValues: RedsysPaymentFormValues;
}

export const RedsysPaymentForm = withFormik<RedsysPaymentFormProps, RedsysPaymentFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => ({
    merchantCode: defaultValues.merchantCode,
    terminal: defaultValues.terminal,
    secretKey: defaultValues.secretKey,
  }),
  validationSchema: redsysPaymentFormSchema,
})(RedsysPayment);
