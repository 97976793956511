import { gql } from 'Operations/__generated__';

export const UPDATE_WELCOME_MESSAGES = gql(/* GraphQL */ `
  mutation UpdateWelcomeMessages($where: GalleryPresetConfigInputType!, $data: [WelcomeMessageInput!]!) {
    updateWelcomeMessages(where: $where, data: $data) {
      id
      content
      imageUrl
    }
  }
`);
