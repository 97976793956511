import { useContext, useMemo } from 'react';

import { Button } from 'antd';

import { camelCase } from 'lodash';

import Container from 'Components/Atoms/Container';
import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon, { IconName } from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import { Colors, FontSize, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { MollieStatus } from 'Operations/__generated__/graphql';

const MollieStatusContent = styled(ContentContainer)`
  display: flex;
  width: fit-content;
  padding-bottom: ${Metrics.smallMargin}px;
`;

const IconContainer = styled.div`
  display: flex;
  margin-right: ${Metrics.baseMargin}px;
  padding-right: ${Metrics.baseMargin}px;
  border-right: 1px solid ${Colors.border};
  box-sizing: border-box;
`;

const RemoveContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${Metrics.smallMargin}px;
  padding-top: ${Metrics.smallMargin}px;
  border-top: 1px solid ${Colors.border};
  box-sizing: border-box;
`;

const MollieStatusCard = ({
  status,
  onDisableClick,
  ...props
}: {
  status: MollieStatus;
  onDisableClick: () => void;
  className?: string;
}) => {
  const { t } = useContext(LocalizationContext);

  const iconName = useMemo((): IconName => {
    switch (status) {
      case MollieStatus.COMPLETED:
        return 'check';
      case MollieStatus.IN_REVIEW:
        return 'search';
      case MollieStatus.NEEDS_DATA:
      default:
        return 'info';
    }
  }, [status]);

  const iconColor = useMemo(() => {
    switch (status) {
      case MollieStatus.COMPLETED:
        return Colors.success;
      case MollieStatus.IN_REVIEW:
        return Colors.waiting;
      case MollieStatus.NEEDS_DATA:
      default:
        return Colors.danger;
    }
  }, [status]);

  return (
    <MollieStatusContent shadow rounded padding="baseMargin" {...props}>
      <Container align="center">
        <IconContainer>
          <Icon name={iconName} size={Metrics.largeMargin} color={iconColor} />
        </IconContainer>
        <Text>{t(`app.settings.payments.mollie.status.${camelCase(status)}`)}</Text>
      </Container>
      <RemoveContainer>
        <Button size="small" danger onClick={onDisableClick}>
          <Icon name="close" size={FontSize.medium} /> {t('app.settings.payments.mollie.disable')}
        </Button>
      </RemoveContainer>
    </MollieStatusContent>
  );
};

export default MollieStatusCard;
