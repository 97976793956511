import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Button } from 'antd';
import { useLocation, useParams } from 'react-router-dom';

import { snakeCase } from 'lodash';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';

import { BreadcrumbContent } from 'Components/Molecules/Breadcrumb';
import Header from 'Components/Molecules/Header';
import OrderTable from 'Components/Molecules/Tables/OrderTable';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useOrdersCategoriesMenu } from 'Hooks/useOrdersCategoriesMenu';
import { useRoles } from 'Hooks/useRoles';

import { GetOrdersWhereInput, OrderItemStatus, OrderType } from 'Operations/__generated__/graphql';

import { GET_ORDER_LIST } from 'Operations/Queries/Order/GetOrderList';
import { GET_ORDERS_COUNT } from 'Operations/Queries/Order/GetOrdersCount';
import { GET_ORDERS_FOR_LABO } from 'Operations/Queries/Order/GetOrdersForLabo';

const StyledTableContentContainer = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  margin-top: ${Metrics.baseMargin}px;
`;

const PER_PAGE = 20;

const OrdersIndex = () => {
  const { t } = useContext(LocalizationContext);
  const location = useLocation();
  const { status } = useParams();
  const { openModal } = useModals();
  useOrdersCategoriesMenu();
  const { isAdmin, isSales, isLabo } = useRoles();

  const statusFilter = useMemo(
    () => (status ? (snakeCase(status)?.toUpperCase() as OrderItemStatus) : undefined),
    [status],
  );

  const canManageAllOrders = useMemo(() => {
    return isAdmin || isSales || isLabo;
  }, [isAdmin, isLabo, isSales]);

  const isCrossAccountsEnabled = useMemo(() => {
    return canManageAllOrders && location.pathname.endsWith('/fotostudio');
  }, [canManageAllOrders, location.pathname]);

  const [paginationParams, setPaginationParams] = useState<GetOrdersWhereInput>({
    page: 1,
    perPage: PER_PAGE,
    order: OrderType.DESC,
    status: undefined,
    search: undefined,
  });
  const page = paginationParams.page || 1;

  const [getOrders, { data: ordersData, loading: isGetOrdersLoading }] = useLazyQuery(GET_ORDER_LIST, {
    fetchPolicy: 'cache-and-network',
  });
  const [getOrdersForLabo, { data: laboOrdersData, loading: isLaboOrdersLoading }] = useLazyQuery(GET_ORDERS_FOR_LABO, {
    fetchPolicy: 'cache-and-network',
  });

  const { data: ordersInProgressCountData, loading: isGetOrdersCountLoading } = useQuery(GET_ORDERS_COUNT, {
    skip: !isCrossAccountsEnabled,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        crossAccounts: isCrossAccountsEnabled,
        status: OrderItemStatus.WAITING,
      },
    },
  });

  useEffect(() => {
    setPaginationParams(params => ({
      ...params,
      status: statusFilter,
    }));
  }, [statusFilter]);

  const handleGetOrders = useCallback(
    (params: GetOrdersWhereInput) => {
      if (isCrossAccountsEnabled) {
        getOrdersForLabo({
          variables: {
            where: params,
          },
        });
      } else {
        getOrders({
          variables: {
            where: params,
          },
        });
      }
    },
    [getOrders, getOrdersForLabo, isCrossAccountsEnabled],
  );

  const data = useMemo(
    () => (isCrossAccountsEnabled ? laboOrdersData?.getOrdersForLabo : ordersData?.getOrders),
    [isCrossAccountsEnabled, laboOrdersData, ordersData],
  );

  useEffect(() => {
    handleGetOrders(paginationParams);
  }, [handleGetOrders, paginationParams]);

  const handleOnChangeTable = async (params: GetOrdersWhereInput) => {
    setPaginationParams(params);
  };

  const breadcrumb = useMemo(() => {
    const bc: BreadcrumbContent[] = [];

    if (!isLabo) {
      bc.push({ text: t('app.menu.home'), url: '/app/dashboard' });
    }
    bc.push({
      text: t('app.common.order', { count: 2 }),
      url: bc.length > 0 ? (isCrossAccountsEnabled ? '/app/orders/fotostudio' : '/app/orders') : undefined,
    });

    return bc;
  }, [isCrossAccountsEnabled, isLabo, t]);

  const headerButtons = useMemo(() => {
    if (
      isCrossAccountsEnabled &&
      !isGetOrdersCountLoading &&
      ordersInProgressCountData &&
      ordersInProgressCountData.getOrders?._count > 0
    ) {
      return [
        <Button
          key="download"
          size="large"
          type="primary"
          onClick={() => openModal('DOWNLOAD_LABO_PHOTOS')}
          icon={<Icon name="download" />}
        >
          {t('app.order.downloadPhotos')}
        </Button>,
      ];
    }
  }, [isCrossAccountsEnabled, isGetOrdersCountLoading, openModal, ordersInProgressCountData, t]);

  return (
    <>
      <Header title={t('app.common.order', { count: 2 })} breadcrumbContent={breadcrumb} buttons={headerButtons} />
      <Layout>
        <StyledTableContentContainer>
          <OrderTable
            ordersTotal={data?._count || 0}
            data={data?.edges || []}
            loading={isGetOrdersLoading || isLaboOrdersLoading}
            paginationCurrent={page}
            paginationSize={paginationParams.perPage || PER_PAGE}
            defaultStatus={statusFilter}
            handleOnChange={handleOnChangeTable}
            crossAccounts={isCrossAccountsEnabled}
            shouldHideGallery={isCrossAccountsEnabled}
            onRefetch={() => handleGetOrders({ ...paginationParams, crossAccounts: isCrossAccountsEnabled })}
          />
        </StyledTableContentContainer>
      </Layout>
    </>
  );
};

export default OrdersIndex;
