import React, { useCallback, useContext, useMemo } from 'react';

import { Alert, App, Button, Dropdown, Flex } from 'antd';
import { createStyles } from 'antd-style';

import { getWelcomeMessageTemplates } from 'constants/WelcomeMessageTemplates';
import ReactMarkdown from 'react-markdown';

import { FieldArray, withFormik } from 'formik';
import { Form } from 'formik-antd';

import Icon from 'Components/Atoms/Icon';

import WelcomeMessagesSortableList from 'Components/Molecules/WelcomeMessagesSortableList';

import { LocalizationContext } from 'i18n';

import { WelcomeMessageFormProps, WelcomeMessageFormValues, WelcomeMessageProps } from './types';

const useStyles = createStyles(({ css, responsive, token }) => ({
  header: css`
    display: flex;
    gap: ${token.size}px;
    justify-content: space-between;
    align-items: center;
    ${responsive.lg} {
      flex-direction: column;
    }
  `,
  spacer: css`
    flex: 1;
  `,
  alert: css`
    flex: 1;

    & p:last-child {
      margin-bottom: 0;
    }
  `,
}));

const WelcomeMessage = <T extends WelcomeMessageFormValues>({
  values,
  isSubmitting,
  dirty,
  defaultValues,
  resetForm,
  setFieldValue,
  locale,
  accessPolicy,
}: WelcomeMessageProps<T>) => {
  const { t } = useContext(LocalizationContext);
  const { styles } = useStyles();
  const { modal } = App.useApp();

  // Get templates for current locale and accessPolicy
  const welcomeMessageTemplates = useMemo(
    () => (!!locale && !!accessPolicy ? getWelcomeMessageTemplates({ locale, accessPolicy }) : []),
    [locale, accessPolicy],
  );

  // Function to insert a predefined message template
  const handleInsertTemplate = useCallback(
    ({ templateId }: { templateId: string }) => {
      const template = welcomeMessageTemplates.find(t => t.id === templateId);
      if (!template) return;

      const replaceWithTemplateMessages = () => {
        // Generate unique negative IDs
        const messagesToAdd = template.messages.map(msg => ({
          ...msg,
          // Avoid ID conflicts by using negative IDs
          id: Math.random() * -1,
          assetId: msg.assetId || null,
          imageUrl: msg.imageUrl || null,
        }));

        // Remove all current messages
        setFieldValue('welcomeMessages', messagesToAdd);
      };

      if (values.welcomeMessages.length > 0) {
        modal.confirm({
          title: t('app.gallery.welcomeMessage.confirmTemplateTitle'),
          content: t('app.gallery.welcomeMessage.confirmTemplateReplace'),
          onOk: replaceWithTemplateMessages,
        });
      } else {
        replaceWithTemplateMessages();
      }
    },
    [modal, t, setFieldValue, values.welcomeMessages, welcomeMessageTemplates],
  );

  return (
    <Form>
      <Flex vertical gap="middle">
        {(values.welcomeMessages.length > 0 || defaultValues.welcomeMessages.length > 0) && (
          <div className={styles.header}>
            {values.welcomeMessages.length > 0 ? (
              <Alert
                type="info"
                className={styles.alert}
                message={<ReactMarkdown>{t('app.gallery.welcomeMessage.info')}</ReactMarkdown>}
              />
            ) : (
              <div className={styles.spacer} />
            )}
            <Flex vertical gap="middle">
              <Flex gap="middle" justify="flex-end" align="flex-start">
                <Button type="dashed" onClick={() => resetForm()} disabled={isSubmitting || !dirty}>
                  {t('app.common.reset')}
                </Button>
                <Button type="primary" htmlType="submit" loading={isSubmitting} disabled={isSubmitting || !dirty}>
                  {t('app.common.save')}
                </Button>
              </Flex>
              <Dropdown
                menu={{
                  items: welcomeMessageTemplates.map(template => ({
                    key: template.id,
                    label: template.name,
                    onClick: () =>
                      handleInsertTemplate({
                        templateId: template.id,
                      }),
                  })),
                }}
                placement="bottomRight"
                trigger={['click']}
              >
                <Button icon={<Icon name="file" />} type="default" disabled={isSubmitting}>
                  {t('app.gallery.welcomeMessage.template.label')}
                </Button>
              </Dropdown>
            </Flex>
          </div>
        )}

        <FieldArray name="welcomeMessages">
          {arrayHelpers => <WelcomeMessagesSortableList arrayHelpers={arrayHelpers} disabled={isSubmitting} />}
        </FieldArray>
      </Flex>
    </Form>
  );
};

export const WelcomeMessageForm = withFormik<WelcomeMessageFormProps, WelcomeMessageFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => ({
    welcomeMessages: defaultValues.welcomeMessages || [],
  }),
  enableReinitialize: true,
})(WelcomeMessage);
