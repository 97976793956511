import { FontSize, FontWeight, Metrics } from 'Themes';

import styled, { css } from 'styled-components';

interface Props {
  weight?: keyof typeof FontWeight;
  align?: 'left' | 'center' | 'right';
  size?: 'title' | 'subtitle' | 'regular' | 'medium' | 'small' | 'tiny';
  color?: string;
  numberOfLines?: number;
  space?: 'baseMargin' | 'smallMargin' | 'tinyMargin';
  children: React.ReactNode;
}

const Text = styled.p<Props>`
  font-family: 'Lato', sans-serif;
  font-weight: ${({ weight = 'regular' }: Props) => FontWeight[weight]};
  font-size: ${({ size = 'regular' }: Props) => FontSize[size]}px;
  text-align: ${({ align = 'left' }: Props) => align};
  color: ${({ color = '#000' }: Props) => color};
  margin-bottom: ${({ space }: Props) => (space ? `${Metrics[space]}px` : '0')};
  ${({ numberOfLines }) =>
    numberOfLines &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${numberOfLines};
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;

export default Text;
