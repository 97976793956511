import { useCallback, useContext } from 'react';

import { useMutation } from '@apollo/client';
import { App } from 'antd';
import { ColorFactory } from 'antd/es/color-picker/color';

import { EditGalleryThemeForm, EditGalleryThemePayload } from 'Forms/GalleryTheme';

import { LocalizationContext } from 'i18n';

import { useGalleryCommon } from 'Hooks/useGalleryCommon';

import { getRGBString } from 'Helpers/TransformColor';

import { UPDATE_GALLERY_THEME } from 'Operations/Mutations/GalleryTheme/UpdateGalleryTheme';

const GalleryThemeTab = ({ galleryId, galleryPresetId }: { galleryId?: number; galleryPresetId?: number }) => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();

  const { data } = useGalleryCommon({ galleryId, galleryPresetId });

  const [updateGalleryTheme] = useMutation(UPDATE_GALLERY_THEME);

  const handleSubmit = useCallback(
    async ({ values, formikBag }: EditGalleryThemePayload) => {
      formikBag.setSubmitting(true);
      try {
        await updateGalleryTheme({
          variables: {
            where: {
              id: values.id,
            },
            data: {
              backgroundColor: getRGBString(values.backgroundColor, true),
              primaryColor: getRGBString(values.primaryColor, true),
              secondaryColor: getRGBString(values.secondaryColor, true),
              menuBackgroundColor: getRGBString(values.menuBackgroundColor, true),
              paymentBackgroundColor: getRGBString(values.paymentBackgroundColor, true),
              titleFont: values.titleFont,
              buttonFont: values.buttonFont,
              buttonRadius: values.buttonRadius,
              photoRadius: values.photoRadius,
            },
          },
        });

        formikBag.setSubmitting(false);
        message.success(t('app.message.gallery.update.success'));
      } catch (error) {
        message.error(t(`app.message.error.somethingWentWrong`));

        formikBag.setSubmitting(false);
      }
    },
    [updateGalleryTheme],
  );

  return (
    data?.id &&
    data.theme && (
      <EditGalleryThemeForm
        galleryId={data.id}
        defaultValues={{
          id: data.theme.id,
          primaryColor: new ColorFactory(`rgba(${data.theme.primaryColor})`),
          secondaryColor: new ColorFactory(`rgba(${data.theme.secondaryColor})`),
          backgroundColor: new ColorFactory(`rgba(${data.theme.backgroundColor})`),
          menuBackgroundColor: new ColorFactory(`rgba(${data.theme.menuBackgroundColor})`),
          paymentBackgroundColor: new ColorFactory(`rgba(${data.theme.paymentBackgroundColor})`),
          buttonFont: data.theme.buttonFont,
          titleFont: data.theme.titleFont,
          buttonRadius: data.theme.buttonRadius,
          photoRadius: data.theme.photoRadius,
        }}
        onSubmit={handleSubmit}
      />
    )
  );
};

export default GalleryThemeTab;
