import React, { ReactNode, useContext } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Card, CardProps, Flex } from 'antd';
import { createStyles } from 'antd-style';

import Icon from 'Components/Atoms/Icon';

import { LocalizationContext } from 'i18n';

interface SortableCardProps extends Omit<CardProps, 'id'> {
  id: number;
  children: ReactNode;
}

const useStyles = createStyles(({ css, token }) => ({
  dragHandle: css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${token.sizeXXS}px;
    width: 100%;
    padding: ${token.paddingXXS}px 0;
    color: ${token.colorTextSecondary};
    font-size: 12px;
    cursor: grab;
    background-color: ${token.colorBgContainer};
    border-bottom: 1px solid ${token.colorBorderSecondary};
    border-top-left-radius: ${token.borderRadiusLG - 1}px;
    border-top-right-radius: ${token.borderRadiusLG - 1}px;

    &:hover {
      color: ${token.colorPrimary};
      background-color: ${token.colorBgTextHover};
    }
  `,
}));

const SortableCard = ({ id, children, cover, style, className, ...props }: SortableCardProps) => {
  const { t } = useContext(LocalizationContext);
  const { styles: globalStyles } = useStyles();
  const { attributes, listeners, transform, transition, setNodeRef } = useSortable({
    id,
    transition: {
      duration: 500,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });

  const customStyle = {
    ...style,
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={customStyle} className={className} {...attributes}>
      <Card
        cover={
          <Flex vertical style={{ position: 'relative' }}>
            <div {...listeners} className={globalStyles.dragHandle} title={t('app.action.dragToReorder')}>
              <Icon name="drag" /> {t('app.action.dragToReorder')}
            </div>
            {cover}
          </Flex>
        }
        {...props}
      >
        {children}
      </Card>
    </div>
  );
};

export default SortableCard;
