import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, App, Flex, Spin, Switch } from 'antd';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Text from 'Components/Atoms/Text';

import StripeCard from 'Components/Molecules/StripeStatusCard';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { PaymentStatus } from 'Operations/__generated__/graphql';

import { ME } from 'Operations/Queries/User/Me';

import { DISABLE_STRIPE_PAYMENT } from 'Operations/Mutations/User/DisableStripePayment';
import { UPDATE_PAYMENTS_ACTIVATION } from 'Operations/Mutations/User/UpdatePaymentsActivation';
import { UPDATE_STRIPE_PAYMENT } from 'Operations/Mutations/User/UpdateStripePayment';

import { usePaymentTabStyles } from './styles';

const StripeUrl = process.env.REACT_APP_STRIPE_CONNECT_URL;

const StripeTab = () => {
  const { message } = App.useApp();
  const { t, isInitialized } = useContext(LocalizationContext);
  const { styles } = usePaymentTabStyles();

  const isStripeCodeSend = useRef(false);

  const { data: currentUser, loading: isUserLoading } = useQuery(ME, {
    fetchPolicy: 'cache-first',
  });

  const [updateStripePayment] = useMutation(UPDATE_STRIPE_PAYMENT);
  const [disableStripePayment] = useMutation(DISABLE_STRIPE_PAYMENT);
  const [updatePaymentsActivation, { loading: isToggleLoading }] = useMutation(UPDATE_PAYMENTS_ACTIVATION);

  const isConfigured = useMemo(
    () => currentUser?.me.stripePayment.status !== PaymentStatus.NOT_CONFIGURED,
    [currentUser?.me.stripePayment.status],
  );

  const isEnabled = useMemo(() => currentUser?.me.stripePayment.isEnabled, [currentUser?.me.stripePayment.isEnabled]);

  const updateStripe = useCallback(
    async ({ stripeCode }: { stripeCode: string }) => {
      try {
        isStripeCodeSend.current = true;

        await updateStripePayment({
          variables: {
            data: {
              code: stripeCode,
            },
          },
        });

        message.success(t('app.message.payments.stripe.update.success'));
      } catch (error) {
        console.log(error);
        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [t, updateStripePayment],
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const stripeCode = urlParams.get('code');
    const stripeScope = urlParams.get('scope');

    if (stripeCode && stripeScope && !isStripeCodeSend.current && isInitialized) {
      updateStripe({ stripeCode });
    }
  }, [updateStripe, isInitialized]);

  const handleGoToStripe = () => {
    if (StripeUrl) {
      window.location.assign(StripeUrl);
    } else {
      message.error(t('app.message.error.somethingWentWrong'));
    }
  };

  const handleDisableStripe = useCallback(async () => {
    try {
      await disableStripePayment();

      message.success(t('app.message.payments.stripe.disable.success'));
    } catch (error) {
      console.log(error);
      message.error(t('app.message.error.somethingWentWrong'));
    }
  }, [disableStripePayment, t]);

  const handleTogglePayment = useCallback(async () => {
    await updatePaymentsActivation({
      variables: {
        data: {
          isStripeGalleriesEnabled: !isEnabled,
        },
      },
    });

    message.success(t(`app.settings.payments.toggle.${!isEnabled ? 'enabled' : 'disabled'}`));
  }, [updatePaymentsActivation, isEnabled, message, t]);

  return (
    <ContentContainer className={styles.container} padding="baseMargin" shadow rounded>
      {isConfigured && (
        <Flex gap="small" align="center">
          <Switch onChange={handleTogglePayment} value={isEnabled} loading={isToggleLoading} />
          <Text onClick={handleTogglePayment}>
            {t(`app.settings.payments.toggle.${isEnabled ? 'enabled' : 'disabled'}`)}
          </Text>
        </Flex>
      )}
      <Alert type="info" message={t('app.settings.payments.stripe.description')} />
      {isUserLoading && <Spin />}
      {!isUserLoading && !isConfigured && (
        <img className={styles.image} src={Images.stripeConnect} alt="Stripe Connect" onClick={handleGoToStripe} />
      )}
      {!isUserLoading && currentUser && isConfigured && (
        <StripeCard
          className={styles.card}
          status={currentUser.me.stripePayment.status}
          onDisableClick={handleDisableStripe}
        />
      )}
    </ContentContainer>
  );
};

export default StripeTab;
