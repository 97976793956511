import React, { useCallback, useContext, useMemo } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, App, Flex, Spin, Switch } from 'antd';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Text from 'Components/Atoms/Text';

import { OnSubmitPayload, OtherPaymentForm } from 'Forms/PaymentOther';

import { LocalizationContext } from 'i18n';

import { ME } from 'Operations/Queries/User/Me';

import { UPDATE_OTHER_PAYMENT } from 'Operations/Mutations/User/UpdateOtherPayment';
import { UPDATE_PAYMENTS_ACTIVATION } from 'Operations/Mutations/User/UpdatePaymentsActivation';

import { usePaymentTabStyles } from './styles';

const OtherTab = () => {
  const { message } = App.useApp();
  const { t } = useContext(LocalizationContext);
  const { styles } = usePaymentTabStyles();

  const { data: currentUser, loading: isUserLoading } = useQuery(ME, {
    fetchPolicy: 'cache-first',
  });

  const [updateOtherPayment] = useMutation(UPDATE_OTHER_PAYMENT);
  const [updatePaymentsActivation, { loading: isToggleLoading }] = useMutation(UPDATE_PAYMENTS_ACTIVATION);

  const isEnabled = useMemo(() => currentUser?.me.otherPayment.isEnabled, [currentUser?.me.otherPayment.isEnabled]);

  const handleOnSubmit = useCallback(
    async ({ values, formikBag }: OnSubmitPayload) => {
      try {
        await updateOtherPayment({
          variables: { data: { content: values.content || '' } },
        });

        message.success(t('app.message.payments.other.update.success'));
        formikBag.setSubmitting(false);
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
        formikBag.setSubmitting(false);
      }
    },
    [updateOtherPayment, message, t],
  );

  const handleTogglePayment = useCallback(async () => {
    await updatePaymentsActivation({
      variables: {
        data: {
          isOtherPaymentGalleriesEnabled: !isEnabled,
        },
      },
    });

    message.success(t(`app.settings.payments.toggle.${!isEnabled ? 'enabled' : 'disabled'}`));
  }, [updatePaymentsActivation, isEnabled, message, t]);

  return (
    <ContentContainer className={styles.container} padding="baseMargin" shadow rounded>
      <Alert type="info" message={t('app.settings.payments.other.description')} />
      {isUserLoading && <Spin />}
      <Flex gap="small" align="flex-start">
        <Switch onChange={handleTogglePayment} value={isEnabled} loading={isToggleLoading} />
        <Text onClick={handleTogglePayment}>
          {t(`app.settings.payments.toggle.${isEnabled ? 'enabled' : 'disabled'}`)}
        </Text>
      </Flex>
      {!isUserLoading && (
        <OtherPaymentForm
          defaultValues={{
            content: currentUser?.me.otherPayment.content,
          }}
          onSubmit={handleOnSubmit}
        />
      )}
    </ContentContainer>
  );
};

export default OtherTab;
