import { useContext } from 'react';

import { Tabs } from 'antd';

import { LocalizationContext } from 'i18n';

import GalleryCoverTab from './GalleryCoverTab';
import GallerySpacingTab from './GallerySpacingTab';
import GalleryThemeTab from './GalleryThemeTab';

interface Props {
  galleryId?: number;
  galleryPresetId?: number;
}

const GalleryAppearanceTab = (props: Props) => {
  const { t } = useContext(LocalizationContext);

  return (
    <div>
      <Tabs
        tabPosition="left"
        items={[
          {
            key: 'theme',
            label: t('app.gallery.tabs.theme', { count: 1 }),
            children: <GalleryThemeTab {...props} />,
          },
          {
            key: 'cover',
            label: t('app.gallery.tabs.cover'),
            children: <GalleryCoverTab {...props} />,
          },
          {
            key: 'spacing',
            label: t('app.gallery.tabs.display'),
            children: <GallerySpacingTab {...props} />,
          },
        ]}
      />
    </div>
  );
};

export default GalleryAppearanceTab;
