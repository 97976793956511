import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import { App, Flex } from 'antd';

import { UpdateVideoForm, VideoGalleryFormPayload } from 'Components/Molecules/Form/Video';

import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import useGallery from 'Hooks/useGallery';

import { GalleryAdmin } from 'Operations/__generated__/graphql';

import { UPDATE_VIDEO_GALLERY } from 'Operations/Mutations/Gallery/updateVideoGallery';

interface Props {
  galleryId: number;
}
const ASPECT_RATIO = 16 / 9;

const StyledDiv = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const ExtraVideo = ({ galleryId }: Props) => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const videoRef = useRef<HTMLIFrameElement>(null);
  const [videoHeight, setVideoHeight] = useState(0);

  const { gallery } = useGallery({ id: galleryId });

  const [updateVideo, { loading: isUpdateLoading }] = useMutation(UPDATE_VIDEO_GALLERY);

  useEffect(() => {
    const resizeVideo = () => {
      if (videoRef.current?.offsetWidth) {
        setVideoHeight(videoRef.current?.offsetWidth / ASPECT_RATIO);
      }
    };
    if (videoRef.current) {
      resizeVideo();
      window.addEventListener('resize', resizeVideo);
    }

    return () => window.removeEventListener('resize', resizeVideo);
  }, []);

  // Remplacer l'useEffect de parsing par un useMemo
  const currentParsedVideo = useMemo(() => {
    if (!gallery?.video) return '';

    if (gallery?.video.includes('youtube.com/') || gallery?.video.includes('youtu.be/')) {
      const match = gallery?.video.match(
        /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?feature=player_embedded&v=|embed\?origin=http[^]*?&feature=embed_resp&url=|watch\?feature=embed_resp&v=))([^?&"'>]+)/,
      );
      if (match && match[1]) {
        const videoId = match[1];
        return `https://www.youtube.com/embed/${videoId}`;
      }
    } else if (gallery?.video.includes('vimeo.com/')) {
      const match = gallery?.video.match(/vimeo\.com\/([0-9]*)/);
      if (match && match[1]) {
        const videoId = match[1];
        return `https://player.vimeo.com/video/${videoId}`;
      }
    }
    return '';
  }, [gallery?.video]);

  const handleSubmit = useCallback(
    (data: VideoGalleryFormPayload) => {
      try {
        updateVideo({
          variables: { where: { id: galleryId }, data: { video: data.values.video } },
          update(cache, { data }) {
            const videoData = data?.updateGallery;
            if (videoData?.__typename === 'GalleryAdmin') {
              cache.modify<GalleryAdmin>({
                id: cache.identify({ id: galleryId, __typename: videoData.__typename }),
                fields: {
                  video: () => {
                    return videoData.video ?? null;
                  },
                },
              });
            }
          },
        });
        if (!isUpdateLoading) {
          data.formikBag.setSubmitting(false);
        }
        message.success(t('app.message.video.update.success'));
      } catch (error) {
        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [galleryId, isUpdateLoading, t, updateVideo],
  );

  return (
    <Flex vertical flex={1} gap="middle">
      <UpdateVideoForm
        onSubmit={e => {
          handleSubmit(e);
        }}
        defaultValues={{ video: gallery?.video }}
      />
      <StyledDiv ref={videoRef}>
        {currentParsedVideo != '' && (
          <iframe
            title="video"
            frameBorder="none"
            width="100%"
            height={videoHeight}
            src={currentParsedVideo}
            allowFullScreen
          />
        )}
      </StyledDiv>
    </Flex>
  );
};

export default ExtraVideo;
