import { useContext, useState } from 'react';

import { Flex } from 'antd';

import { camelCase } from 'lodash';

import Wizard, { WizardStep, WizardSubmitHandler } from 'Components/Molecules/Form/Wizard';
import ModalRecapItem from 'Components/Molecules/ModalRecapItem';
import ModalTip from 'Components/Molecules/ModalTip';

import { Images } from 'Themes';

import i18n, { LocalizationContext } from 'i18n';

import usePortal from 'Hooks/usePortal';

import dayjs from 'Services/DayjsService';
import yup from 'Services/YupService';

import { GalleryAccessPolicy, GalleryStatus, GalleryWorkmode, Locale } from 'Operations/__generated__/graphql';

import GalleryAccessPolicyStep from './Gallery/GalleryAccessPolicyStep';
import GalleryInfoStep from './Gallery/GalleryInfoStep';
import GalleryPresetStep from './Gallery/GalleryPresetStep';
import GalleryProductsStep from './Gallery/GalleryProductsStep';
import GalleryWorkmodeStep from './Gallery/GalleryWorkmodeStep';
import { GalleryFormValues } from './Gallery/types';

const accessPolicyLabel = {
  [GalleryAccessPolicy.PUBLIC]: 'app.gallery.accessTypes.publicNew.title',
  [GalleryAccessPolicy.ACCESS_CODE]: 'app.gallery.accessTypes.accessCodeNew.title',
  [GalleryAccessPolicy.OLD_PUBLIC]: 'app.gallery.accessTypes.publicNew.title',
  [GalleryAccessPolicy.OLD_ACCESS_CODE]: 'app.gallery.accessTypes.accessCodeNew.title',
};

const isFiveMinutesRound = function (value?: string | null) {
  return value ? dayjs(value).get('minutes') % 5 === 0 : true;
};

export interface GalleryFormProps {
  name: 'new' | 'edit';
  onSubmit: WizardSubmitHandler<GalleryFormValues>;
  defaultValues: GalleryFormValues;
}

const GalleryForm = ({ name, defaultValues, onSubmit }: GalleryFormProps) => {
  const Portal = usePortal(document.getElementById('side-modal-content'));
  const { t } = useContext(LocalizationContext);
  const [values, setValues] = useState(defaultValues);

  const galleryId = defaultValues.id;
  const presetId = defaultValues.galleryPresetId;

  return (
    <>
      <Wizard
        defaultStep={galleryId || presetId ? 'informations' : undefined}
        initialValues={defaultValues}
        onSubmit={onSubmit}
        layout="vertical"
        formType={name === 'edit' ? 'update' : 'create'}
        onSnapshotChange={setValues}
      >
        {!galleryId && (
          <WizardStep<GalleryFormValues>
            name="galleryPreset"
            title={t('app.common.preset')}
            validationSchema={yup.object({
              galleryPresetId: yup.number().nullable(),
            })}
            shouldSkip={({ galleryPresetId }, currentStep) => !!galleryPresetId && currentStep === 0}
          >
            <GalleryPresetStep />
          </WizardStep>
        )}
        <WizardStep<GalleryFormValues>
          name="workmode"
          title={t('app.common.mode')}
          validationSchema={yup.object({
            workmode: yup.mixed<GalleryWorkmode>().required(),
          })}
          shouldSkip={({ galleryPresetId }, currentStep) => !!galleryPresetId && currentStep === 0}
        >
          <GalleryWorkmodeStep />
        </WizardStep>
        {
          // Only show the access policy step if the gallery is not an old gallery
          defaultValues.accessPolicy !== GalleryAccessPolicy.OLD_PUBLIC &&
            defaultValues.accessPolicy !== GalleryAccessPolicy.OLD_ACCESS_CODE && (
              <WizardStep<GalleryFormValues>
                name="accessPolicy"
                title={t('app.gallery.accessType')}
                validationSchema={yup.object({
                  accessPolicy: yup.mixed<GalleryAccessPolicy>().required(),
                  isEmailRequired: yup.boolean(),
                })}
                shouldSkip={({ galleryPresetId }, currentStep) => !!galleryPresetId && currentStep === 0}
              >
                <GalleryAccessPolicyStep />
              </WizardStep>
            )
        }
        <WizardStep<GalleryFormValues>
          name="products"
          title={t('app.common.products', { count: 2 })}
          validationSchema={yup.object({ catalogId: yup.number().nullable() })}
          shouldSkip={({ galleryPresetId }, currentStep) => !!galleryPresetId && currentStep === 0}
        >
          <GalleryProductsStep />
        </WizardStep>
        <WizardStep<GalleryFormValues>
          name="informations"
          title={t('app.common.information', { count: 2 })}
          validationSchema={yup.object({
            projectId: yup.number().nullable(),
            name: yup.string().trim().required(),
            url: yup.string().nullable(),
            video: yup
              .string()
              .nullable()
              .test('is-video-url-valid', i18n.t('app.gallery.wrongVideoLink'), value => {
                if (value) {
                  return /youtube\.com\/|youtu\.be\/|vimeo\.com\//.test(value);
                }
                return true;
              }),
            contactId: yup.number().when('accessPolicy', {
              is: GalleryAccessPolicy.ACCESS_CODE,
              then: yup.number().required(),
              otherwise: yup.number().nullable(),
            }),
            secondaryContactIds: yup.array().nullable(),
            status: yup.mixed<GalleryStatus>().required(),
            locale: yup.mixed<Locale>().required(),
            availableAt: yup
              .string()
              .trim()
              .nullable()
              .test('availableAt5min', () => i18n.t('app.form.errors.date.round5minutes'), isFiveMinutesRound),
            expiredAt: yup
              .string()
              .trim()
              .test('expiredAt5min', () => i18n.t('app.form.errors.date.round5minutes'), isFiveMinutesRound)
              .when('availableAt', {
                is: (availableAt: string) => !!availableAt,
                // @ts-ignore
                then: yup.string().nullable().after(yup.ref('availableAt')),
                otherwise: yup.string().nullable(),
              }),
            shootedAt: yup.string().trim().nullable(),
            accessCode: yup.string().trim().when('accessPolicy', {
              is: GalleryAccessPolicy.ACCESS_CODE,
              then: yup.string().required(),
              otherwise: yup.string().nullable(),
            }),
          })}
        >
          <GalleryInfoStep />
        </WizardStep>
      </Wizard>
      {Portal && (
        <Portal>
          <ModalTip title={t('app.gallery.recap.title')}>
            <Flex vertical gap="middle">
              {name === 'new' && (
                <ModalRecapItem
                  icon={values.galleryPresetId ? Images.galleryModel : Images.configuration}
                  title={t('app.common.preset')}
                  value={values.galleryPresetName || t('app.gallery.noPreset.title')}
                />
              )}

              {values.workmode && (
                <ModalRecapItem
                  icon={
                    values.workmode === GalleryWorkmode.READY_FOR_SALE ? Images.readyToSaleMode : Images.retouchMode
                  }
                  title={t('app.common.mode')}
                  value={t(`app.gallery.settings.defaultWorkmode.${camelCase(values.workmode)}`)}
                />
              )}
              {values.accessPolicy && (
                <ModalRecapItem
                  icon={
                    values.accessPolicy === GalleryAccessPolicy.PUBLIC ? Images.publicPolicy : Images.accessCodePolicy
                  }
                  title={t('app.gallery.accessType')}
                  value={t(accessPolicyLabel[values.accessPolicy])}
                />
              )}
              <ModalRecapItem
                icon={values.catalogId ? Images.cart : Images.eye}
                title={t('app.common.products', { count: 2 })}
                value={values.catalogName || t('app.gallery.catalog.disable.title')}
              />
              <ModalTip title={t('app.common.didYouKnow')} content={t('app.gallery.availibilityDetails')} />
            </Flex>
          </ModalTip>
        </Portal>
      )}
    </>
  );
};

export default GalleryForm;
