import React, { useContext, useMemo } from 'react';

import { Tabs } from 'antd';

import ExtraSpotify from 'Components/Molecules/Extra/Spotify';
import ExtraVideo from 'Components/Molecules/Extra/video';

import GalleryStatsTab from 'Pages/App/Galleries/Tabs/GalleryStatsTab';
import GalleryWelcomeTab from 'Pages/App/Galleries/Tabs/GalleryWelcomeTab';

import { LocalizationContext } from 'i18n';

interface GalleryExtraTabProps {
  galleryId?: number;
  galleryPresetId?: number;
}

const GalleryExtraTab = ({ galleryId, galleryPresetId }: GalleryExtraTabProps) => {
  const { t } = useContext(LocalizationContext);

  const extraTabs = useMemo(() => {
    const tabs = [
      {
        label: 'Spotify',
        key: 'spotify',
        children: <ExtraSpotify galleryId={galleryId} galleryPresetId={galleryPresetId} />,
      },
    ];

    if (galleryId) {
      tabs.push({
        label: t('app.gallery.extra.tabs.video'),
        key: 'video',
        children: <ExtraVideo galleryId={galleryId} />,
      });
      tabs.push({
        label: t('app.gallery.tabs.stats'),
        key: 'stats',
        children: <GalleryStatsTab galleryId={galleryId} />,
      });
    }

    return tabs;
  }, [galleryId, galleryPresetId, t]);

  return <Tabs tabPosition="left" items={extraTabs} />;
};

export default GalleryExtraTab;
