import { gql } from 'Operations/__generated__';

export const UPDATE_PAYMENTS_ACTIVATION = gql(/* GraphQL */ `
  mutation UpdatePaymentsActivation($data: UserPaymentsActivationInputType!) {
    updatePaymentsActivation(data: $data) {
      id
      otherPayment {
        isEnabled
      }
      molliePayment {
        isEnabled
      }
      redsysPayment {
        isEnabled
      }
      stripePayment {
        isEnabled
      }
    }
  }
`);
