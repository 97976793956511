import React, { useContext } from 'react';

import { Button, Flex } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Switch } from 'formik-antd';

import Editor from 'Components/Molecules/Form/Editor';

import { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

export interface OtherPaymentDefaultValues {
  content?: string | null;
}

export interface OtherPaymentFormValues {
  content?: string;
}

const OtherPayment = <T extends OtherPaymentFormValues>({ values, isSubmitting }: FormikProps<T>) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Form layout="vertical">
      <Form.Item name="content" required hasFeedback={false}>
        <Editor name="content" />
      </Form.Item>
      <Flex justify="flex-end" align="center">
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
          {t('app.common.edit')}
        </Button>
      </Flex>
    </Form>
  );
};

const otherPaymentSchema = yup.object({
  content: yup.string().trim(),
});

const otherPaymentFormSchema: yup.SchemaOf<OtherPaymentFormValues> = otherPaymentSchema.defined();

export interface OnSubmitPayload {
  values: OtherPaymentFormValues;
  formikBag: FormikBag<OtherPaymentFormProps, OtherPaymentFormValues>;
}

export interface OtherPaymentFormProps {
  onSubmit: (payload: OnSubmitPayload) => void;
  defaultValues: OtherPaymentDefaultValues;
}

export const OtherPaymentForm = withFormik<OtherPaymentFormProps, OtherPaymentFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => ({
    content: defaultValues.content || '',
  }),
  validationSchema: otherPaymentFormSchema,
})(OtherPayment);
