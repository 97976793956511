import { createStyles } from 'antd-style';

export const usePaymentTabStyles = createStyles(({ css, token }) => ({
  container: css`
    flex: 1;
    align-self: flex-start;
    width: 67%;
    gap: ${token.size}px;
  `,
  image: css`
    display: block;
    width: 200px;
    height: 45px;
    cursor: pointer;
  `,
  card: css`
    width: 100%;
  `,
}));
