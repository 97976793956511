import React, { useContext } from 'react';

import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Badge, Button, Card, Flex, Popconfirm, Switch, Typography } from 'antd';
import { createStyles } from 'antd-style';

import { FieldArrayRenderProps, useFormikContext } from 'formik';

import Icon from 'Components/Atoms/Icon';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import SortableCard from 'Components/Molecules/Cards/SortableCard';
import ImageInput from 'Components/Molecules/Form/ImageInput';

import { WelcomeMessageFormValues } from 'Forms/WelcomeMessage/types';

import { LocalizationContext } from 'i18n';

import MarkdownEditor, { EditorButton } from './Form/MarkdownEditor';

const useStyles = createStyles(({ css, token }) => ({
  card: css`
    min-width: 400px;
    max-width: 30vw;
    width: 30%;

    .ant-card-body {
      padding: 0;
      max-height: 300px;
      overflow-y: auto;
    }

    .ant-card-actions {
      cursor: default;
    }
  `,
  addCard: css`
    width: 20vw;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
    }
  `,
  enableCard: css`
    width: 100%;
    max-width: 400px;
    text-align: center;
  `,
  imageInput: css`
    width: 100%;
    height: 120px;

    &:focus {
      border-color: ${token.colorPrimary};
      box-shadow: 0 0 0 2px ${token.colorPrimaryBg};
    }
  `,
  markdownEditor: css``,
  welcomeIcon: css`
    font-size: 48px;
    margin-bottom: ${token.marginSM}px;
    color: ${token.colorPrimary};
  `,
  switchContainer: css`
    margin-top: ${token.marginMD}px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${token.marginXS}px;
  `,
  stepBadge: css`
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 1;
  `,
}));

export interface WelcomeMessageItem {
  id: number;
  content: string;
  imageUrl?: string;
  assetId?: string;
  file?: File;
}

interface WelcomeMessagesSortableListProps {
  arrayHelpers: FieldArrayRenderProps;
  disabled?: boolean;
}

const WelcomeMessagesSortableList: React.FC<WelcomeMessagesSortableListProps> = ({ arrayHelpers, disabled }) => {
  const { values } = useFormikContext<WelcomeMessageFormValues>();
  const { t } = useContext(LocalizationContext);
  const { styles, theme } = useStyles();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const items = values.welcomeMessages;

  const addWelcomeMessage = React.useCallback(() => {
    arrayHelpers.push({
      id: (items.length + 1) * -1, // Negative id to avoid conflicts with existing ids
      content: '',
      imageUrl: '',
      assetId: undefined,
    });
  }, [arrayHelpers, items.length]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={event => {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
          const oldIndex = items.findIndex(item => item.id === active.id);
          const newIndex = items.findIndex(item => item.id === over.id);
          arrayHelpers.move(oldIndex, newIndex);
        }
      }}
    >
      <SortableContext items={items.map(i => i.id)} strategy={rectSortingStrategy}>
        <Flex gap="middle" wrap="wrap">
          {items.map((item, index) => (
            <SortableCard
              key={item.id}
              id={item.id}
              cover={
                <Flex vertical>
                  <Badge className={styles.stepBadge} count={index + 1} color={theme.colorPrimary} overflowCount={99} />
                  <ImageInput
                    name={`welcomeMessages.${index}.file`}
                    accept="image/png, image/jpeg, image/svg+xml, image/gif"
                    preview={item.imageUrl}
                    className={styles.imageInput}
                    disabled={disabled}
                    maxFileSize={2 * 1024 * 1024} // 2MB
                    imageInfo={t('app.gallery.welcomeMessage.imageInfo')}
                  />
                </Flex>
              }
              actions={[
                <Popconfirm
                  key="delete"
                  disabled={disabled}
                  title={t('app.confirm.delete')}
                  onConfirm={() => arrayHelpers.remove(index)}
                >
                  <RoundButton icon="delete" danger disabled={disabled} />
                </Popconfirm>,
              ]}
              className={styles.card}
            >
              <MarkdownEditor
                name={`welcomeMessages.${index}.content`}
                buttons={[
                  EditorButton.BOLD_ITALIC_UNDERLINE,
                  EditorButton.LINK,
                  EditorButton.UNORDERED_LIST,
                  EditorButton.ORDERED_LIST,
                  EditorButton.BLOCK_TYPE,
                ]}
                placeholder={t('app.gallery.welcomeMessage.placeholder')}
                className={styles.markdownEditor}
              />
            </SortableCard>
          ))}

          {items.length > 0 ? (
            <div>
              <Card
                className={styles.addCard}
                onClick={!disabled ? addWelcomeMessage : undefined}
                hoverable={!disabled}
              >
                <Button type="text">
                  <Icon name="add" />
                  {t('app.action.addStep')}
                </Button>
              </Card>
            </div>
          ) : (
            <Card className={styles.enableCard}>
              <Icon name="message" size={24} className={styles.welcomeIcon} />
              <Typography.Title level={4}>{t('app.gallery.welcomeMessage.title')}</Typography.Title>
              <Typography.Paragraph>{t('app.gallery.welcomeMessage.info')}</Typography.Paragraph>
              <div className={styles.switchContainer}>
                <Typography.Text>{t('app.common.enable')}</Typography.Text>
                <Switch
                  onChange={checked => {
                    if (checked) {
                      addWelcomeMessage();
                    }
                  }}
                />
              </div>
            </Card>
          )}
        </Flex>
      </SortableContext>
    </DndContext>
  );
};

export default WelcomeMessagesSortableList;
