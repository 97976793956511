import { useContext, useMemo } from 'react';

import { Button } from 'antd';

import { camelCase } from 'lodash';

import Container from 'Components/Atoms/Container';
import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon, { IconName } from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import { Colors, FontSize, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { PaymentStatus } from 'Operations/__generated__/graphql';

const StripeStatusContent = styled(ContentContainer)`
  display: flex;
  padding-bottom: ${Metrics.smallMargin}px;
`;

const IconContainer = styled.div`
  display: flex;
  margin-right: ${Metrics.baseMargin}px;
  padding-right: ${Metrics.baseMargin}px;
  border-right: 1px solid ${Colors.border};
  box-sizing: border-box;
`;

const RemoveContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${Metrics.smallMargin}px;
  padding-top: ${Metrics.smallMargin}px;
  border-top: 1px solid ${Colors.border};
  box-sizing: border-box;
`;

const StripeStatusCard = ({
  status,
  onDisableClick,
  ...props
}: {
  status: PaymentStatus;
  onDisableClick: () => void;
  className?: string;
}) => {
  const { t } = useContext(LocalizationContext);

  const iconName = useMemo((): IconName => {
    switch (status) {
      case PaymentStatus.COMPLETED:
      default:
        return 'check';
    }
  }, [status]);

  const iconColor = useMemo(() => {
    switch (status) {
      case PaymentStatus.COMPLETED:
        return Colors.success;
    }
  }, [status]);

  return (
    <StripeStatusContent shadow rounded padding="baseMargin" {...props}>
      <Container align="center">
        <IconContainer>
          <Icon name={iconName} size={Metrics.largeMargin} color={iconColor} />
        </IconContainer>
        <Text>{t(`app.settings.payments.stripe.status.${camelCase(status)}`)}</Text>
      </Container>
      <RemoveContainer>
        <Button size="small" danger onClick={onDisableClick}>
          <Icon name="close" size={FontSize.medium} /> {t('app.settings.payments.stripe.disable')}
        </Button>
      </RemoveContainer>
    </StripeStatusContent>
  );
};

export default StripeStatusCard;
