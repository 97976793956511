import React, { useContext } from 'react';

import { Tabs } from 'antd';

import { compact } from 'lodash';

import { LocalizationContext } from 'i18n';

import GalleryCommentsTab from './GalleryCommentsTab';
import GalleryWelcomeTab from './GalleryWelcomeTab';

interface GalleryCommunicationTabProps {
  galleryId?: number;
  galleryPresetId?: number;
}

const GalleryCommunicationTab = ({ galleryId, galleryPresetId }: GalleryCommunicationTabProps) => {
  const { t } = useContext(LocalizationContext);

  if (galleryPresetId) {
    return <GalleryWelcomeTab galleryPresetId={galleryPresetId} />;
  }

  return (
    <Tabs
      tabPosition="left"
      items={compact([
        {
          label: t('app.gallery.welcomeMessage.title'),
          key: 'welcome',
          children: <GalleryWelcomeTab galleryId={galleryId} galleryPresetId={galleryPresetId} />,
        },
        !!galleryId && {
          label: t('app.common.comment', { count: 2 }),
          key: 'comments',
          children: <GalleryCommentsTab galleryId={galleryId} />,
        },
      ])}
    />
  );
};

export default GalleryCommunicationTab;
