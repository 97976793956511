import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { Input, InputRef } from 'antd';
import { SearchProps } from 'antd/es/input';

import { debounce } from 'lodash';

const DebounceSearchInput = forwardRef<
  InputRef,
  SearchProps & { debounceLength: number; debounceTime: number; onChange: (value: string) => void }
>(({ debounceLength, debounceTime, ...props }) => {
  const [displayValue, setDisplayValue] = useState(props.value);

  const debouncedSetValue = useRef(
    debounce(value => {
      if (props.onChange) {
        props.onChange(value);
      }
    }, debounceTime),
  ).current;

  useEffect(() => {
    return () => {
      if (debouncedSetValue) {
        debouncedSetValue.cancel();
      }
    };
  }, [debouncedSetValue]);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDisplayValue(e.target.value);
      if (e.target.value.length >= debounceLength) {
        debouncedSetValue.cancel();
        debouncedSetValue(e.target.value);
      }
    },
    [debouncedSetValue, setDisplayValue],
  );

  return <Input.Search {...props} value={displayValue} onChange={handleOnChange} />;
});

export default DebounceSearchInput;
