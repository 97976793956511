import Images from 'Themes/Images';

// Import template images
import downloadFilesImage from 'Images/welcomeMessages/downloadFiles.jpg';

import DE from 'i18n/locales/de.json';
import EN from 'i18n/locales/en.json';
import ES from 'i18n/locales/es.json';
import FR from 'i18n/locales/fr.json';
import IT from 'i18n/locales/it.json';
import NL from 'i18n/locales/nl.json';

import { GalleryAccessPolicy } from 'Operations/__generated__/graphql';

// Group all locales in a single object
const locales: Record<string, typeof EN> = {
  en: EN,
  fr: FR,
  nl: NL,
  de: DE,
  es: ES,
  it: IT,
};

// Welcome message type
export interface WelcomeMessageTemplate {
  content: string;
  assetId?: string;
  imageUrl?: string;
}

type WelcomeMessageTemplateKey = keyof typeof locales.en.app.gallery.welcomeMessage.template.content;
type WelcomeMessageTitleKey = keyof typeof locales.en.app.gallery.welcomeMessage.template.title;

const assetIdsByEnvironment: Record<string, Record<WelcomeMessageTemplateKey, string>> = {
  staging: {
    quickGuide: '1/images/07JcCWtsthKUQfnH1d',
    includedProducts: '1/images/jNJdzHxQDL0UbUfRS1',
    favorites: '1/images/gJnbhnELgBr4rcPNir',
    downloadFiles: '1/images/fJgAHJnYeWvsDSKgyn',
    personalSpace: '1/images/G8S1q9XykAMEG1UaZb',
  },
  prod: {
    quickGuide: '1/images/07JcCWtsthKUQfnH1d',
    includedProducts: '1/images/jNJdzHxQDL0UbUfRS1',
    favorites: '1/images/gJnbhnELgBr4rcPNir',
    downloadFiles: '1/images/fJgAHJnYeWvsDSKgyn',
    personalSpace: '1/images/G8S1q9XykAMEG1UaZb',
  },
};

const getCurrentAssetIds = (): Record<WelcomeMessageTemplateKey, string> => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return assetIdsByEnvironment.prod;
    case 'development':
    default:
      return assetIdsByEnvironment.staging;
  }
};

const getTemplateImages = (): Record<WelcomeMessageTemplateKey, { assetId?: string; imageUrl?: string }> => {
  const currentAssetIds = getCurrentAssetIds();

  return {
    quickGuide: {
      assetId: currentAssetIds.quickGuide,
      imageUrl: Images.welcomeMessageQuickGuide,
    },
    includedProducts: {
      assetId: currentAssetIds.includedProducts,
      imageUrl: Images.welcomeMessageIncludedProducts,
    },
    favorites: {
      assetId: currentAssetIds.favorites,
      imageUrl: Images.welcomeMessageFavorites,
    },
    downloadFiles: {
      assetId: currentAssetIds.downloadFiles,
      imageUrl: Images.welcomeMessageDownloadFiles,
    },
    personalSpace: {
      assetId: currentAssetIds.personalSpace,
      imageUrl: Images.welcomeMessagePersonalSpace,
    },
  };
};

const templateImages = getTemplateImages();

// Welcome message template group type
export interface WelcomeMessageTemplateGroup {
  id: string;
  name: string;
  messages: WelcomeMessageTemplate[];
  // Defines which policies can use this template (if empty, all can use it)
  applicablePolicies?: GalleryAccessPolicy[];
}

// Type for templates by locale
export type LocalizedWelcomeMessageTemplates = Record<string, WelcomeMessageTemplateGroup[]>;

// Helper to create a message template based on locale
const createMessageTemplate = ({
  templateKey,
  locale,
}: {
  templateKey: WelcomeMessageTemplateKey;
  locale: string;
}): WelcomeMessageTemplate => ({
  content: locales[locale].app.gallery.welcomeMessage.template.content[templateKey],
  assetId: templateImages[templateKey].assetId,
  imageUrl: templateImages[templateKey].imageUrl,
});

// Create a template group based on locale
const createTemplateGroup = ({
  id,
  locale,
  messageKeys,
}: {
  id: WelcomeMessageTitleKey;
  locale: string;
  messageKeys: WelcomeMessageTemplateKey[];
}): WelcomeMessageTemplateGroup => {
  return {
    id,
    name: locales[locale].app.gallery.welcomeMessage.template.title[id],
    messages: messageKeys.map(key => createMessageTemplate({ templateKey: key, locale })),
  };
};

// Template definitions
const getTemplateGroups = ({
  locale,
}: {
  locale: string;
}): Record<WelcomeMessageTitleKey, WelcomeMessageTemplateGroup> => ({
  quickGuide: createTemplateGroup({
    id: 'quickGuide',
    locale,
    messageKeys: ['quickGuide'],
  }),
  fullGuide: createTemplateGroup({
    id: 'fullGuide',
    locale,
    messageKeys: ['includedProducts', 'favorites', 'downloadFiles', 'personalSpace'],
  }),
});

// Templates for public galleries
const getPublicTemplates = ({ locale }: { locale: string }): WelcomeMessageTemplateGroup[] => {
  const templates = getTemplateGroups({ locale });
  return [templates['quickGuide'], templates['fullGuide']];
};

// Templates for private galleries (with access code)
const getPrivateTemplates = ({ locale }: { locale: string }): WelcomeMessageTemplateGroup[] => {
  const templates = getTemplateGroups({ locale });
  return [templates['fullGuide'], templates['quickGuide']];
};

// Get all available templates for a locale
export const getAllWelcomeMessageTemplates = ({
  locale = 'en',
}: {
  locale?: string;
} = {}): Record<GalleryAccessPolicy, WelcomeMessageTemplateGroup[]> => {
  const publicTemplates = getPublicTemplates({ locale });
  const privateTemplates = getPrivateTemplates({ locale });

  return {
    [GalleryAccessPolicy.PUBLIC]: publicTemplates,
    [GalleryAccessPolicy.ACCESS_CODE]: privateTemplates,
    [GalleryAccessPolicy.OLD_PUBLIC]: publicTemplates,
    [GalleryAccessPolicy.OLD_ACCESS_CODE]: privateTemplates,
  };
};

// Get templates based on locale and access policy
export const getWelcomeMessageTemplates = ({
  locale = 'en',
  accessPolicy = GalleryAccessPolicy.PUBLIC,
}: {
  locale?: string;
  accessPolicy?: GalleryAccessPolicy;
} = {}): WelcomeMessageTemplateGroup[] => {
  // Select appropriate template list based on policy
  if ([GalleryAccessPolicy.PUBLIC, GalleryAccessPolicy.OLD_PUBLIC].includes(accessPolicy)) {
    return getPublicTemplates({ locale });
  } else {
    return getPrivateTemplates({ locale });
  }
};
