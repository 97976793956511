import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, App, Flex, Spin, Switch } from 'antd';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Text from 'Components/Atoms/Text';

import MollieCard from 'Components/Molecules/MollieStatusCard';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { MollieStatus } from 'Operations/__generated__/graphql';

import { ME } from 'Operations/Queries/User/Me';

import { DISABLE_MOLLIE_PAYMENT } from 'Operations/Mutations/User/DisableMolliePayment';
import { UPDATE_MOLLIE_PAYMENT } from 'Operations/Mutations/User/UpdateMolliePayment';
import { UPDATE_PAYMENTS_ACTIVATION } from 'Operations/Mutations/User/UpdatePaymentsActivation';

import { usePaymentTabStyles } from './styles';

const MollieUrl = process.env.REACT_APP_MOLLIE_CONNECT_URL;

const MollieTab = () => {
  const { message } = App.useApp();
  const { t, isInitialized } = useContext(LocalizationContext);
  const { styles } = usePaymentTabStyles();

  const { data: currentUser, loading: isUserLoading } = useQuery(ME, {
    fetchPolicy: 'cache-and-network',
  });

  const [updateMolliePayment] = useMutation(UPDATE_MOLLIE_PAYMENT);
  const [disableMolliePayment] = useMutation(DISABLE_MOLLIE_PAYMENT);
  const [updatePaymentsActivation, { loading: isToggleLoading }] = useMutation(UPDATE_PAYMENTS_ACTIVATION);

  const isMollieCodeSend = useRef(false);

  const isConfigured = useMemo(
    () => currentUser?.me.molliePayment.status !== MollieStatus.NOT_CONFIGURED,
    [currentUser?.me.molliePayment.status],
  );

  const isEnabled = useMemo(() => currentUser?.me.molliePayment.isEnabled, [currentUser?.me.molliePayment.isEnabled]);

  const updateMollie = useCallback(
    async ({ mollieCode }: { mollieCode: string }) => {
      try {
        isMollieCodeSend.current = true;

        await updateMolliePayment({
          variables: {
            data: {
              code: mollieCode,
            },
          },
        });

        message.success(t('app.message.payments.mollie.update.success'));
      } catch (error) {
        console.log(error);
        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [t, updateMolliePayment],
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mollieCode = urlParams.get('code');
    const stripeScope = urlParams.get('scope');

    if (mollieCode && !stripeScope && !isMollieCodeSend.current && isInitialized) {
      updateMollie({ mollieCode });
    }
  }, [updateMollie, isInitialized]);

  const handleGoToMollie = () => {
    if (MollieUrl) {
      window.location.assign(MollieUrl);
    } else {
      message.error(t('app.message.error.somethingWentWrong'));
    }
  };

  const handleDisableMollie = useCallback(async () => {
    try {
      await disableMolliePayment();

      message.success(t('app.message.payments.mollie.disable.success'));
    } catch (error) {
      console.log(error);
      message.error(t('app.message.error.somethingWentWrong'));
    }
  }, [disableMolliePayment, t]);

  const handleTogglePayment = useCallback(async () => {
    await updatePaymentsActivation({
      variables: {
        data: {
          isMollieGalleriesEnabled: !isEnabled,
        },
      },
    });

    message.success(t(`app.settings.payments.toggle.${!isEnabled ? 'enabled' : 'disabled'}`));
  }, [updatePaymentsActivation, isEnabled, message, t]);

  return (
    <ContentContainer className={styles.container} padding="baseMargin" shadow rounded>
      {!isConfigured && <Alert type="info" message={t('app.settings.payments.mollie.description')} />}
      {isConfigured && (
        <Flex gap="small" align="flex-start">
          <Switch onChange={handleTogglePayment} value={isEnabled} loading={isToggleLoading} />
          <Text onClick={handleTogglePayment}>
            {t(`app.settings.payments.toggle.${isEnabled ? 'enabled' : 'disabled'}`)}
          </Text>
        </Flex>
      )}

      {isUserLoading && <Spin />}
      {!isUserLoading && !isConfigured && (
        <img className={styles.image} src={Images.mollieConnect} alt="Mollie Connect" onClick={handleGoToMollie} />
      )}
      {!isUserLoading && currentUser && isConfigured && (
        <MollieCard
          className={styles.card}
          status={currentUser.me.molliePayment.status}
          onDisableClick={handleDisableMollie}
        />
      )}
    </ContentContainer>
  );
};

export default MollieTab;
