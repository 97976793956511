import { gql } from 'Operations/__generated__';

export const ME = gql(/* GraphQL */ `
  query Me {
    me {
      id
      companyId
      company {
        id
        bookingUrl
        logoUrl
        name
        vat1Value
      }
      firstname
      lastname
      locale
      hasCRM
      hasGalleries
      providerTypeId
      menuTheme
      currency {
        symbol
      }
      otherPayment {
        content
        isEnabled
      }
      molliePayment {
        status
        isEnabled
      }
      stripePayment {
        status
        isEnabled
      }
      redsysPayment {
        merchantCode
        terminal
        secretKey
        isEnabled
      }
    }
  }
`);
