import React, { useCallback, useContext, useMemo } from 'react';

import { gql, useMutation } from '@apollo/client';
import { App } from 'antd';

import { WelcomeMessageForm } from 'Forms/WelcomeMessage';
import { WelcomeMessageSubmitPayload } from 'Forms/WelcomeMessage/types';

import { LocalizationContext } from 'i18n';

import { useGalleryCommon } from 'Hooks/useGalleryCommon';

import { GalleryAccessPolicy } from 'Operations/__generated__/graphql';

import { UPDATE_WELCOME_MESSAGES } from 'Operations/Mutations/WelcomeMessage/UpdateWelcomeMessages';

interface GalleryWelcomeTabProps {
  galleryId?: number;
  galleryPresetId?: number;
}

const GalleryWelcomeTab = ({ galleryId, galleryPresetId }: GalleryWelcomeTabProps) => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();

  const { data } = useGalleryCommon({
    galleryId,
    galleryPresetId,
  });

  const locale = data?.locale || 'en';
  const accessPolicy = data?.accessPolicy || GalleryAccessPolicy.PUBLIC;

  const [updateWelcomeMessages] = useMutation(UPDATE_WELCOME_MESSAGES);

  const handleUpdateWelcomeMessages = useCallback(
    async ({ values, formikBag: { setSubmitting } }: WelcomeMessageSubmitPayload) => {
      await updateWelcomeMessages({
        variables: {
          where: {
            galleryId,
            galleryPresetId,
          },
          data: values.welcomeMessages.map(({ id, content, file, assetId }, index) => ({
            id: id > 0 ? id : undefined,
            content,
            file,
            assetId,
            position: index + 1,
          })),
        },
        update(cache, { data }) {
          if (!data?.updateWelcomeMessages) return;

          const newMessages = data.updateWelcomeMessages;

          const cacheId = cache.identify(
            galleryId
              ? { __typename: 'GalleryAdmin', id: galleryId }
              : galleryPresetId
              ? { __typename: 'GalleryPreset', id: galleryPresetId }
              : {},
          );

          if (!cacheId) return;

          cache.modify({
            id: cacheId,
            fields: {
              welcomeMessages() {
                return newMessages.map(msg =>
                  cache.writeFragment({
                    data: msg,
                    fragment: gql`
                      fragment NewWelcomeMessage on WelcomeMessage {
                        id
                        content
                        imageUrl
                      }
                    `,
                  }),
                );
              },
            },
          });

          cache.gc();
        },
      });

      setSubmitting(false);
      message.success(t('app.common.saved'));
    },
    [updateWelcomeMessages],
  );

  const defaultValue = useMemo(
    () => ({
      welcomeMessages:
        data?.welcomeMessages?.map(({ id, content, imageUrl }) => ({
          id,
          content,
          imageUrl: imageUrl || undefined,
        })) || [],
    }),
    [data?.welcomeMessages],
  );

  return (
    <WelcomeMessageForm
      defaultValues={defaultValue}
      onSubmit={handleUpdateWelcomeMessages}
      locale={locale}
      accessPolicy={accessPolicy}
    />
  );
};

export default GalleryWelcomeTab;
