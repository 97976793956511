import { useQuery } from '@apollo/client';

import { Gallery, GalleryPreset, GetGalleryPresetQuery, GetGalleryQuery } from 'Operations/__generated__/graphql';

import { GET_GALLERY } from 'Operations/Queries/Gallery/GetGallery';
import { GET_GALLERY_PRESET } from 'Operations/Queries/GalleryPreset/GetGalleryPreset';

// Extract common keys between Gallery and GalleryPreset
type CommonKeys<T, U> = {
  [P in keyof T & keyof U]: T[P] extends U[P] ? P : never;
}[keyof T & keyof U];

// Create a type that only contains common properties
type ExtractCommon<T, U> = {
  [P in CommonKeys<T, U>]?: T[P] | null;
};

// Define GalleryCommon as the common properties between Gallery and GalleryPreset
export type GalleryCommon = ExtractCommon<Gallery, GalleryPreset> & {
  __typename: Gallery['__typename'] | GalleryPreset['__typename'];
  name: string;
};

interface GalleryCommonResponse {
  data?: GalleryCommon;
  rawData?: GetGalleryQuery['getGallery'] | GetGalleryPresetQuery['getGalleryPreset'];
  loading: boolean;
  error: Error | undefined;
  refetch: () => void;
}

interface UseGalleryCommonProps {
  galleryId?: number;
  galleryPresetId?: number;
}

export const useGalleryCommon = ({ galleryId, galleryPresetId }: UseGalleryCommonProps): GalleryCommonResponse => {
  const {
    data: galleryData,
    loading: galleryLoading,
    error: galleryError,
    refetch: galleryRefetch,
  } = useQuery(GET_GALLERY, {
    variables: { where: { id: galleryId! } },
    skip: !galleryId,
  });

  const {
    data: presetData,
    loading: presetLoading,
    error: presetError,
    refetch: presetRefetch,
  } = useQuery(GET_GALLERY_PRESET, {
    variables: { where: { id: galleryPresetId! } },
    skip: !galleryPresetId,
  });

  if (galleryId && galleryData?.getGallery) {
    return {
      data: galleryData.getGallery as unknown as GalleryCommon,
      rawData: galleryData.getGallery,
      loading: galleryLoading,
      error: galleryError,
      refetch: galleryRefetch,
    };
  }

  if (galleryPresetId && presetData?.getGalleryPreset) {
    return {
      data: presetData.getGalleryPreset as unknown as GalleryCommon,
      rawData: presetData.getGalleryPreset,
      loading: presetLoading,
      error: presetError,
      refetch: presetRefetch,
    };
  }

  return {
    loading: galleryLoading || presetLoading,
    error: galleryError || presetError,
    data: undefined,
    rawData: undefined,
    refetch: () => {},
  };
};
