import React, { useContext, useMemo, useState } from 'react';

import 'antd/dist/reset.css';

import '@mdxeditor/editor/style.css';

import Navigation from 'Navigation/RootNavigation';

import i18n, { LocalizationContext, LocalizationContextType } from 'i18n';

import ApolloProvider from 'Providers/ApolloProvider';
import AppLifeCycleProvider from 'Providers/AppLifeCycleProvider';
import ThemeProvider from 'Providers/ThemeProvider';

const App = () => {
  const [locale, setLocale] = useState(i18n.locale);
  const [isInitialized, setIsInitialized] = useState(false);

  const localizationContext = useMemo<LocalizationContextType>(
    () => ({
      t: (scope, options) => i18n.t(scope, { locale, ...options }),
      locale,
      setLocale(newLocale) {
        setLocale(newLocale);
        setIsInitialized(true);
      },
      isInitialized,
    }),
    [locale, isInitialized],
  );

  return (
    <ThemeProvider locale={locale}>
      <LocalizationContext.Provider value={localizationContext}>
        <ApolloProvider>
          <AppLifeCycleProvider>
            <Navigation />
          </AppLifeCycleProvider>
        </ApolloProvider>
      </LocalizationContext.Provider>
    </ThemeProvider>
  );
};

export default App;
